import actions from './actions';

const { 
    DEFECTIVE_LIST_DATA_BEGIN, DEFECTIVE_LIST_DATA_SUCCESS, DEFECTIVE_LIST_DATA_ERR,
    ASSIGN_AGENT_DEFECTIVE_DATA_BEGIN, ASSIGN_AGENT_DEFECTIVE_DATA_SUCCESS, ASSIGN_AGENT_DEFECTIVE_DATA_ERR,ASSIGN_AGENT_DEFECTIVE_DATA_RESET,
    VIEW_DEFECTIVE_DATA_BEGIN, VIEW_DEFECTIVE_DATA_SUCCESS, VIEW_DEFECTIVE_DATA_ERR,
    EDIT_DEFECTIVE_DATA_BEGIN, EDIT_DEFECTIVE_DATA_SUCCESS, EDIT_DEFECTIVE_DATA_ERR, EDIT_DEFECTIVE_DATA_RESET,
    DEFECTIVE_HISTORY_DATA_BEGIN, DEFECTIVE_HISTORY_DATA_SUCCESS, DEFECTIVE_HISTORY_DATA_ERR,

    DEFECTIVE_EXCEL_HEADER_DATA_BEGIN, DEFECTIVE_EXCEL_HEADER_DATA_SUCCESS, DEFECTIVE_EXCEL_HEADER_DATA_ERR,
    DEFECTIVE_SUBMIT_FILE_DATA_BEGIN, DEFECTIVE_SUBMIT_FILE_DATA_SUCCESS, DEFECTIVE_SUBMIT_FILE_DATA_ERR,
    DEFECTIVE_EXCEL_DATA_BEGIN, DEFECTIVE_EXCEL_DATA_SUCCESS,DEFECTIVE_EXCEL_DATA_ERR,

    DEFECTIVE_DATE_BEGIN, DEFECTIVE_DATE_SUCCESS, DEFECTIVE_DATE_ERR,
    DEFECTIVE_SAVE_DATA_BEGIN, DEFECTIVE_SAVE_DATA_SUCCESS,DEFECTIVE_SAVE_DATA_ERR,
    DEFECTIVE_DOWNLOAD_DATA_BEGIN, DEFECTIVE_DOWNLOAD_DATA_SUCCESS, DEFECTIVE_DOWNLOAD_DATA_ERR,


}= actions;

const initialState = { data: [], loading: false, error: null, };

const defectiveListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEFECTIVE_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEFECTIVE_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEFECTIVE_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const assignAgentDefectiveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ASSIGN_AGENT_DEFECTIVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ASSIGN_AGENT_DEFECTIVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ASSIGN_AGENT_DEFECTIVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case ASSIGN_AGENT_DEFECTIVE_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const viewDefectiveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VIEW_DEFECTIVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case VIEW_DEFECTIVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case VIEW_DEFECTIVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const editDefectiveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EDIT_DEFECTIVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EDIT_DEFECTIVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EDIT_DEFECTIVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case EDIT_DEFECTIVE_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const defectiveHistoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEFECTIVE_HISTORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEFECTIVE_HISTORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEFECTIVE_HISTORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const excelDefectiveHeaderReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEFECTIVE_EXCEL_HEADER_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEFECTIVE_EXCEL_HEADER_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEFECTIVE_EXCEL_HEADER_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const submitDefectiveFileReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEFECTIVE_SUBMIT_FILE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEFECTIVE_SUBMIT_FILE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEFECTIVE_SUBMIT_FILE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const defectiveExcelReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEFECTIVE_EXCEL_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEFECTIVE_EXCEL_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEFECTIVE_EXCEL_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const defectiveReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEFECTIVE_DATE_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEFECTIVE_DATE_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEFECTIVE_DATE_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const defectiveReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEFECTIVE_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEFECTIVE_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEFECTIVE_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const defectiveReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEFECTIVE_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEFECTIVE_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEFECTIVE_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

export { 
    defectiveListReducers, assignAgentDefectiveReducers, viewDefectiveReducers, editDefectiveReducers,  defectiveHistoryReducers,
    excelDefectiveHeaderReducers, submitDefectiveFileReducers, defectiveExcelReducers, 
    defectiveReportReducers, defectiveReportSaveReducers, defectiveReportDownloadReducers  
};