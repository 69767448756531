import actions from './actions';

const { 
    QUALITY_DASHBOARD_LEAD_SOURCE_DATA_BEGIN, QUALITY_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS, QUALITY_DASHBOARD_LEAD_SOURCE_DATA_ERR,
    QUALITY_DASHBOARD_CATEGORY_DATA_BEGIN, QUALITY_DASHBOARD_CATEGORY_DATA_SUCCESS, QUALITY_DASHBOARD_CATEGORY_DATA_ERR,
    QUALITY_DASHBOARD_TICKET_DATA_BEGIN, QUALITY_DASHBOARD_TICKET_DATA_SUCCESS, QUALITY_DASHBOARD_TICKET_DATA_ERR,
    QUALITY_DASHBOARD_SUMMARY_DATA_BEGIN, QUALITY_DASHBOARD_SUMMARY_DATA_SUCCESS, QUALITY_DASHBOARD_SUMMARY_DATA_ERR,
    QUALITY_DASHBOARD_REGION_DATA_BEGIN, QUALITY_DASHBOARD_REGION_DATA_SUCCESS, QUALITY_DASHBOARD_REGION_DATA_ERR,
    
    QUALITY_FORM_DATA_BEGIN, QUALITY_FORM_DATA_SUCCESS, QUALITY_FORM_DATA_ERR, 
    QUALITY_FORM_SUBMIT_DATA_BEGIN, QUALITY_FORM_SUBMIT_DATA_SUCCESS, QUALITY_FORM_SUBMIT_DATA_ERR,QUALITY_FORM_SUBMIT_DATA_RESET, 
    
    GET_AUDIT_QUALITY_LIST_DATA_BEGIN, GET_AUDIT_QUALITY_LIST_DATA_SUCCESS, GET_AUDIT_QUALITY_LIST_DATA_ERR,
    SUBMIT_QUALITY_SHARE_DATA_BEGIN, SUBMIT_QUALITY_SHARE_DATA_SUCCESS, SUBMIT_QUALITY_SHARE_DATA_ERR, SUBMIT_QUALITY_SHARE_DATA_RESET,
    GET_AGENT_QUALITY_LIST_DATA_BEGIN, GET_AGENT_QUALITY_LIST_DATA_SUCCESS, GET_AGENT_QUALITY_LIST_DATA_ERR,
    VIEW_AUDIT_QUALITY_DATA_BEGIN,  VIEW_AUDIT_QUALITY_DATA_SUCCESS, VIEW_AUDIT_QUALITY_DATA_ERR,
   
    QUALITY_REPORT_DATA_BEGIN, QUALITY_REPORT_DATA_SUCCESS, QUALITY_REPORT_DATA_ERR,
    QUALITY_REPORT_SAVE_DATA_BEGIN, QUALITY_REPORT_SAVE_DATA_SUCCESS, QUALITY_REPORT_SAVE_DATA_ERR, 
    QUALITY_REPORT_DOWNLOAD_DATA_BEGIN, QUALITY_REPORT_DOWNLOAD_DATA_SUCCESS, QUALITY_REPORT_DOWNLOAD_DATA_ERR, 
   
} = actions;

const initialState = { data: '', loading: false, error: null, };

const initialStateDashboard = { data: '', start:null, end:null, loading: false, error: null, };

const qualityDashbaordLeadSourceReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case QUALITY_DASHBOARD_LEAD_SOURCE_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case QUALITY_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case QUALITY_DASHBOARD_LEAD_SOURCE_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const qualityDashboardCategoryReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case QUALITY_DASHBOARD_CATEGORY_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case QUALITY_DASHBOARD_CATEGORY_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case QUALITY_DASHBOARD_CATEGORY_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const qualityDashboardTicketReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case QUALITY_DASHBOARD_TICKET_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case QUALITY_DASHBOARD_TICKET_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case QUALITY_DASHBOARD_TICKET_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const qualityDashbaordSummaryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case QUALITY_DASHBOARD_SUMMARY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case QUALITY_DASHBOARD_SUMMARY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case QUALITY_DASHBOARD_SUMMARY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const qualityDashbaordRegionReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case QUALITY_DASHBOARD_REGION_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case QUALITY_DASHBOARD_REGION_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case QUALITY_DASHBOARD_REGION_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const qualityFormReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case QUALITY_FORM_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case QUALITY_FORM_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case QUALITY_FORM_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const qualityFormSubmitReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case QUALITY_FORM_SUBMIT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case QUALITY_FORM_SUBMIT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case QUALITY_FORM_SUBMIT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        
        case QUALITY_FORM_SUBMIT_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};


const getAuditQualityListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_AUDIT_QUALITY_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case GET_AUDIT_QUALITY_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case GET_AUDIT_QUALITY_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

const submitAuditQualityShareAgentReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SUBMIT_QUALITY_SHARE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SUBMIT_QUALITY_SHARE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SUBMIT_QUALITY_SHARE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        
        case SUBMIT_QUALITY_SHARE_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const getAgentBasedQualityListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_AGENT_QUALITY_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case GET_AGENT_QUALITY_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case GET_AGENT_QUALITY_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        
        default:
            return state;
    }
};

const viewAuditQualityDetailsReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VIEW_AUDIT_QUALITY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case VIEW_AUDIT_QUALITY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case VIEW_AUDIT_QUALITY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        
        default:
            return state;
    }
};


const qualityReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case QUALITY_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case QUALITY_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case QUALITY_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const qualityReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case QUALITY_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case QUALITY_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case QUALITY_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const qualityReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case QUALITY_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case QUALITY_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case QUALITY_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};



export { 
    qualityDashbaordLeadSourceReducers, qualityDashboardCategoryReducers, qualityDashboardTicketReducers, qualityDashbaordSummaryReducers, qualityDashbaordRegionReducers, 
    qualityFormReducers, qualityFormSubmitReducers, 
    getAuditQualityListReducers, submitAuditQualityShareAgentReducers, getAgentBasedQualityListReducers, viewAuditQualityDetailsReducers,
    qualityReportReducers, qualityReportSaveReducers, qualityReportDownloadReducers 
};
