import React, { lazy } from 'react';
import { Switch, Route,Redirect ,useRouteMatch} from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard                 = lazy(() => import('../../container/legal/Dashboard'));

const RegisteredList            = lazy(() => import('../../container/legal/RegisteredList'));

const ViewDetails               = lazy(() => import('../../container/legal/ViewDetails'));
const EditDetails               = lazy(() => import('../../container/legal/EditComplaintDetails'))

const LegalHappy                = lazy(() => import('../../container/legal/LegalHappy'));
const ViewHappyDetails          = lazy(() => import('../../container/legal/ViewHappyDetails'));

const FollowLegal               = lazy(() => import('../../container/legal/FollowLegal'));
const ViewFollowDetails         = lazy(() => import('../../container/legal/ViewFollowDetails'));

const Report                    = lazy(() => import('../../container/legal/NewReport'));
const DownloadReport            = lazy(() => import('../../container/legal/DownloadReport'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function LegalRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            

            <Route exact path={`${path}/follow/:id`} component={RegisteredList} />
            <Route exact path={`${path}/follow/:id/view`} component={ViewDetails} />
            <Route exact path={`${path}/follow/:id/edit`} component={EditDetails} />

            
            <Route exact path={`${path}/legal_case_happy`} component={LegalHappy} />
            <Route exact path={`${path}/legal_case_happy/view`} component={ViewHappyDetails} />

            <Route exact path={`${path}/legal_case_log`} component={FollowLegal} />
            <Route exact path={`${path}/legal_case_log/view`} component={ViewFollowDetails} />

            {/* <Route exact path={`${path}/priority`} component={RegisteredList} />
            <Route exact path={`${path}/scheduled`} component={RegisteredList} /> */}

            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(LegalRoutes);