import actions from './actions';

const { 
    ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_BEGIN, ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS, ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_ERR,
    ESCALATION_DASHBOARD_CATEGORY_DATA_BEGIN, ESCALATION_DASHBOARD_CATEGORY_DATA_SUCCESS, ESCALATION_DASHBOARD_CATEGORY_DATA_ERR,
    ESCALATION_DASHBOARD_TICKET_DATA_BEGIN, ESCALATION_DASHBOARD_TICKET_DATA_SUCCESS, ESCALATION_DASHBOARD_TICKET_DATA_ERR,
    ESCALATION_DASHBOARD_SUMMARY_DATA_BEGIN, ESCALATION_DASHBOARD_SUMMARY_DATA_SUCCESS, ESCALATION_DASHBOARD_SUMMARY_DATA_ERR,
    ESCALATION_DASHBOARD_REGION_DATA_BEGIN, ESCALATION_DASHBOARD_REGION_DATA_SUCCESS, ESCALATION_DASHBOARD_REGION_DATA_ERR,
    ESCALATION_DEPARTMENT_DATA_BEGIN, ESCALATION_DEPARTMENT_DATA_SUCCESS, ESCALATION_DEPARTMENT_DATA_ERR,
    
    ESCALATION_LIST_DATA_BEGIN, ESCALATION_LIST_DATA_SUCCESS, ESCALATION_LIST_DATA_ERR,
    ESCALATION_LEAD_ASSIGN_DATA_BEGIN, ESCALATION_LEAD_ASSIGN_DATA_SUCCESS, ESCALATION_LEAD_ASSIGN_DATA_ERR,  ESCALATION_LEAD_ASSIGN_DATA_RESET,

    GET_AGENT_BASED_ESCALATION_LIST_DATA_BEGIN, GET_AGENT_BASED_ESCALATION_LIST_DATA_SUCCESS, GET_AGENT_BASED_ESCALATION_LIST_DATA_ERR,
    UPDATE_ESCALATION_STATUS_DATA_BEGIN, UPDATE_ESCALATION_STATUS_DATA_SUCCESS, UPDATE_ESCALATION_STATUS_DATA_ERR, UPDATE_ESCALATION_STATUS_DATA_RESET,
    VIEW_ESCALATION_DETAILS_DATA_BEGIN, VIEW_ESCALATION_DETAILS_DATA_SUCCESS, VIEW_ESCALATION_DETAILS_DATA_ERR,

    GET_ESCALATION_STATUS_HISTORY_DATA_BEGIN, GET_ESCALATION_STATUS_HISTORY_DATA_SUCCESS, GET_ESCALATION_STATUS_HISTORY_DATA_ERR,

    ESCALATION_REPORT_DATA_BEGIN, ESCALATION_REPORT_DATA_SUCCESS, ESCALATION_REPORT_DATA_ERR,
    ESCALATION_REPORT_SAVE_DATA_BEGIN, ESCALATION_REPORT_SAVE_DATA_SUCCESS, ESCALATION_REPORT_SAVE_DATA_ERR, 
    ESCALATION_REPORT_DOWNLOAD_DATA_BEGIN, ESCALATION_REPORT_DOWNLOAD_DATA_SUCCESS, ESCALATION_REPORT_DOWNLOAD_DATA_ERR, 
} = actions;

const initialState = { data: [], loading: false, error: null, };
const initialStateDashboard = { data: '', start:null, end:null, loading: false, error: null, };


const escalationDashbaordLeadSourceReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const escalationDashboardCategoryReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case ESCALATION_DASHBOARD_CATEGORY_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case ESCALATION_DASHBOARD_CATEGORY_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case ESCALATION_DASHBOARD_CATEGORY_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const escalationDashboardTicketReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case ESCALATION_DASHBOARD_TICKET_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case ESCALATION_DASHBOARD_TICKET_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case ESCALATION_DASHBOARD_TICKET_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const escalationDashbaordSummaryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ESCALATION_DASHBOARD_SUMMARY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ESCALATION_DASHBOARD_SUMMARY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ESCALATION_DASHBOARD_SUMMARY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const escalationDashbaordRegionReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case ESCALATION_DASHBOARD_REGION_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case ESCALATION_DASHBOARD_REGION_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case ESCALATION_DASHBOARD_REGION_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const escalationDepartmentReducer = (state = initialState, action ) =>{
    const { type, data, err } = action;
    switch (type) {
        case ESCALATION_DEPARTMENT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ESCALATION_DEPARTMENT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                error:null,
                loading: false,
            };
        case ESCALATION_DEPARTMENT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const escalationListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ESCALATION_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ESCALATION_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ESCALATION_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const escalationLeadAssignReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ESCALATION_LEAD_ASSIGN_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ESCALATION_LEAD_ASSIGN_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ESCALATION_LEAD_ASSIGN_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        case ESCALATION_LEAD_ASSIGN_DATA_RESET:
            return initialState
        default:
            return state;
    }
};


const escalationAgentBasedListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_AGENT_BASED_ESCALATION_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case GET_AGENT_BASED_ESCALATION_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case GET_AGENT_BASED_ESCALATION_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};


const updateEscalationStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_ESCALATION_STATUS_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case UPDATE_ESCALATION_STATUS_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case UPDATE_ESCALATION_STATUS_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case UPDATE_ESCALATION_STATUS_DATA_RESET:
            return initialState

        default:
            return state;
    }
};


const escalationStatusHisoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_ESCALATION_STATUS_HISTORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case GET_ESCALATION_STATUS_HISTORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case GET_ESCALATION_STATUS_HISTORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const viewEscalactionReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VIEW_ESCALATION_DETAILS_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case VIEW_ESCALATION_DETAILS_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case VIEW_ESCALATION_DETAILS_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};  
    

const escalationReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ESCALATION_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ESCALATION_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ESCALATION_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const escalationReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ESCALATION_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ESCALATION_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ESCALATION_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const escalationReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ESCALATION_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ESCALATION_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ESCALATION_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};



export { 
    escalationDashbaordLeadSourceReducers, escalationDashboardCategoryReducers, escalationDashboardTicketReducers, escalationDashbaordSummaryReducers, 
    escalationDashbaordRegionReducers, escalationDepartmentReducer,  
    escalationListReducers, escalationLeadAssignReducers, viewEscalactionReducers,
    escalationAgentBasedListReducers,updateEscalationStatusReducers,escalationStatusHisoryReducers,
    escalationReportReducers, escalationReportSaveReducers,escalationReportDownloadReducers 
};
