import React, { Suspense, useEffect, useState} from 'react';
import { Row, Col, Skeleton, notification } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { CardBarChart, CardBarChart2, EChartCard, CardChartStyle, OverviewSalesCard } from './style';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import { Main } from '../styled';
import Heading from '../../components/heading/heading';
import { ChartjsBarChartTransparent } from '../../components/charts/chartjs';
// import { CalendarButtonPageHeader } from '../../components/buttons/calendar-button/calendar-button';


import CategoryBased from './overview/CategoryBased';
import RegionBased from './overview/RegionBased';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeDashboard } from '../../redux/employee/actionCreator';

const chartOptions = {
    legend: {
        display: false,
        labels: {
            display: false,
        },
    },
    scales: {
        yAxes: [
            {
                stacked: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
        ],
        xAxes: [
            {
                stacked: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
        ],
    },
};





const Dashboard = () => {

    const dispatch = useDispatch();

    const employeeDashboard = useSelector(state => state.employeeDashboard);
    
    useEffect(() => {
        if (getEmployeeDashboard) {
            dispatch(getEmployeeDashboard());
        }
    }, [dispatch]);

    
    return (
        <>
            <PageHeader ghost title="Employee Dashboard" />
            <Main>
                <CardChartStyle>
                    <Row gutter={24}>
                        <Col xxl={6} md={6} xs={24} >
                            <Suspense
                                fallback={
                                    <Cards headless>
                                        <Skeleton active />
                                    </Cards>
                                }
                            >
                                <Cards headless>
                                    <OverviewSalesCard>
                                        <div className="icon-box box-secondary">
                                            <img src={require('../../static/img/icon/New Customer.svg').default} alt="" />
                                        </div>
                                        <div className="card-chunk">
                                            <CardBarChart2>
                                                <h2>{employeeDashboard?.data?.total_count}</h2>
                                                <span>Total Employee</span>
                                            </CardBarChart2>
                                        </div>
                                    </OverviewSalesCard>
                                </Cards>
                            </Suspense>
                        </Col>
                        <Col xxl={6} md={6} xs={24}>
                            <Suspense
                                fallback={
                                    <Cards headless>
                                        <Skeleton active />
                                    </Cards>
                                }
                            >
                                <Cards headless>
                                    <OverviewSalesCard>
                                        <div className="icon-box box-primary">
                                            <img src={require('../../static/img/icon/New Customer.svg').default} alt="" />
                                        </div>
                                        <div className="card-chunk">
                                            <CardBarChart2>
                                                <h2>{employeeDashboard?.data?.pai_employee_count}</h2>
                                                <span>Pai Employees</span>
                                            </CardBarChart2>
                                        </div>
                                    </OverviewSalesCard>
                                </Cards>
                            </Suspense>
                        </Col>    
                        <Col xxl={6} md={6} xs={24}>
                            <Suspense
                                fallback={
                                    <Cards headless>
                                        <Skeleton active />
                                    </Cards>
                                }
                            >
                                <Cards headless>
                                    <OverviewSalesCard>
                                        <div className="icon-box box-success">
                                            <img src={require('../../static/img/icon/New Customer.svg').default} alt="" />
                                        </div>
                                        <div className="card-chunk">
                                        <CardBarChart2>
                                            <h2>{employeeDashboard?.data?.isb_employee_count}</h2>
                                            <span>ISB Employee</span>
                                        </CardBarChart2>
                                        </div>
                                    </OverviewSalesCard>
                                </Cards>
                            </Suspense>
                        </Col>
                    </Row>

                    {/* <Row gutter={25}>
                        <Col lg={12} xs={24}>
                            <Suspense
                                fallback={
                                    <Cards headless>
                                        <Skeleton active />
                                    </Cards>
                                }
                            >
                                <CategoryBased />
                            </Suspense>
                        </Col>
                        <Col lg={12} xs={24} >
                            <RegionBased />
                        </Col>
                    </Row> */}
                </CardChartStyle>
            </Main>
        </>
    )
    
}

export default Dashboard