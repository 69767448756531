import actions from './actions';

const { 
    OUTOFWARRANTY_LIST_DATA_BEGIN, OUTOFWARRANTY_LIST_DATA_SUCCESS, OUTOFWARRANTY_LIST_DATA_ERR,
    OUTOFWARRANTY_SCHEDULED_LIST_DATA_BEGIN, OUTOFWARRANTY_SCHEDULED_LIST_DATA_SUCCESS, OUTOFWARRANTY_SCHEDULED_LIST_DATA_ERR,
    ASSIGN_VENDOR_OUTOFWARRANTY_DATA_BEGIN, ASSIGN_VENDOR_OUTOFWARRANTY_DATA_SUCCESS, ASSIGN_VENDOR_OUTOFWARRANTY_DATA_ERR,ASSIGN_VENDOR_OUTOFWARRANTY_DATA_RESET,
    OUTOFWARRANTY_DISPOSITION_LIST_DATA_BEGIN,OUTOFWARRANTY_DISPOSITION_LIST_DATA_SUCCESS,OUTOFWARRANTY_DISPOSITION_LIST_DATA_ERR,
    OUTOFWARRANTY_VENDOR_LIST_DATA_BEGIN, OUTOFWARRANTY_VENDOR_LIST_DATA_SUCCESS, OUTOFWARRANTY_VENDOR_LIST_DATA_ERR,
    OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_BEGIN, OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_SUCCESS, OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_ERR,
    VENDOR_UPDATE_STATUS_DATA_BEGIN, VENDOR_UPDATE_STATUS_DATA_SUCCESS, VENDOR_UPDATE_STATUS_DATA_ERR, VENDOR_UPDATE_STATUS_DATA_RESET,
    VIEW_OUTOFWARRANTY_DATA_BEGIN, VIEW_OUTOFWARRANTY_DATA_SUCCESS, VIEW_OUTOFWARRANTY_DATA_ERR,
    OUTOFWARRANTY_HISTORY_DATA_BEGIN, OUTOFWARRANTY_HISTORY_DATA_SUCCESS, OUTOFWARRANTY_HISTORY_DATA_ERR,
    OUTOFWARRANTY_REPORT_DATE_BEGIN, OUTOFWARRANTY_REPORT_DATE_SUCCESS, OUTOFWARRANTY_REPORT_DATE_ERR,
    OUTOFWARRANTY_REPORT_SAVE_DATA_BEGIN, OUTOFWARRANTY_REPORT_SAVE_DATA_SUCCESS,OUTOFWARRANTY_REPORT_SAVE_DATA_ERR,
    OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_BEGIN, OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_SUCCESS, OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_ERR,

}= actions;

const initialState = { data: [], loading: false, error: null, };


const outofwarrantyListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case OUTOFWARRANTY_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case OUTOFWARRANTY_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case OUTOFWARRANTY_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const outofwarrantyScheduledListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case OUTOFWARRANTY_SCHEDULED_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case OUTOFWARRANTY_SCHEDULED_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case OUTOFWARRANTY_SCHEDULED_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const assignVendorOutofwarrantyReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ASSIGN_VENDOR_OUTOFWARRANTY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ASSIGN_VENDOR_OUTOFWARRANTY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ASSIGN_VENDOR_OUTOFWARRANTY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case ASSIGN_VENDOR_OUTOFWARRANTY_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const outofwarrantyVendorListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case OUTOFWARRANTY_VENDOR_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case OUTOFWARRANTY_VENDOR_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case OUTOFWARRANTY_VENDOR_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const outofwarrantyVendorScheduledListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const outofwarrantyDispositionListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case OUTOFWARRANTY_DISPOSITION_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case OUTOFWARRANTY_DISPOSITION_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case OUTOFWARRANTY_DISPOSITION_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const vendorUpdateStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VENDOR_UPDATE_STATUS_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case VENDOR_UPDATE_STATUS_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case VENDOR_UPDATE_STATUS_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case VENDOR_UPDATE_STATUS_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const viewOutofWarrantyReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VIEW_OUTOFWARRANTY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case VIEW_OUTOFWARRANTY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case VIEW_OUTOFWARRANTY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const outofwarrantyHistoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case OUTOFWARRANTY_HISTORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case OUTOFWARRANTY_HISTORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case OUTOFWARRANTY_HISTORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const outofwarrantyReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case OUTOFWARRANTY_REPORT_DATE_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case OUTOFWARRANTY_REPORT_DATE_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case OUTOFWARRANTY_REPORT_DATE_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const outofwarrantyReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case OUTOFWARRANTY_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case OUTOFWARRANTY_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case OUTOFWARRANTY_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const outofwarrantyReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

export { 
    outofwarrantyListReducers, outofwarrantyScheduledListReducers, assignVendorOutofwarrantyReducers,
    outofwarrantyVendorListReducers,  outofwarrantyVendorScheduledListReducers, outofwarrantyDispositionListReducers,
    outofwarrantyHistoryReducers,viewOutofWarrantyReducers,vendorUpdateStatusReducers,
    outofwarrantyReportReducers, outofwarrantyReportSaveReducers, outofwarrantyReportDownloadReducers  
};