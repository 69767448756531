const actions = {

    REVIEW_DASHBOARD_LEAD_SOURCE_DATA_BEGIN : 'REVIEW_DASHBOARD_LEAD_SOURCE_DATA_BEGIN',
    REVIEW_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS: 'REVIEW_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS',
    REVIEW_DASHBOARD_LEAD_SOURCE_DATA_ERR : 'REVIEW_DASHBOARD_LEAD_SOURCE_DATA_ERR',

    REVIEW_DASHBOARD_CATEGORY_DATA_BEGIN : 'REVIEW_DASHBOARD_CATEGORY_DATA_BEGIN',
    REVIEW_DASHBOARD_CATEGORY_DATA_SUCCESS: 'REVIEW_DASHBOARD_CATEGORY_DATA_SUCCESS',
    REVIEW_DASHBOARD_CATEGORY_DATA_ERR : 'REVIEW_DASHBOARD_CATEGORY_DATA_ERR',

    REVIEW_DASHBOARD_TICKET_DATA_BEGIN : 'REVIEW_DASHBOARD_TICKET_DATA_BEGIN',
    REVIEW_DASHBOARD_TICKET_DATA_SUCCESS: 'REVIEW_DASHBOARD_TICKET_DATA_SUCCESS',
    REVIEW_DASHBOARD_TICKET_DATA_ERR : 'REVIEW_DASHBOARD_TICKET_DATA_ERR',

    REVIEW_DASHBOARD_SUMMARY_DATA_BEGIN : 'REVIEW_DASHBOARD_SUMMARY_DATA_BEGIN',
    REVIEW_DASHBOARD_SUMMARY_DATA_SUCCESS: 'REVIEW_DASHBOARD_SUMMARY_DATA_SUCCESS',
    REVIEW_DASHBOARD_SUMMARY_DATA_ERR : 'REVIEW_DASHBOARD_SUMMARY_DATA_ERR',

    REVIEW_DASHBOARD_REGION_DATA_BEGIN : 'REVIEW_DASHBOARD_REGION_DATA_BEGIN',
    REVIEW_DASHBOARD_REGION_DATA_SUCCESS: 'REVIEW_DASHBOARD_REGION_DATA_SUCCESS',
    REVIEW_DASHBOARD_REGION_DATA_ERR : 'REVIEW_DASHBOARD_REGION_DATA_ERR',
    
    REVIEW_QUERIES_DATA_BEGIN : 'REVIEW_QUERIES_DATA_BEGIN',
    REVIEW_QUERIES_DATA_SUCCESS: 'REVIEW_QUERIES_DATA_SUCCESS',
    REVIEW_QUERIES_DATA_ERR : 'REVIEW_QUERIES_DATA_ERR',

    VIEW_REVIEW_DETAIL_DATA_BEGIN : 'VIEW_REVIEW_DETAIL_DATA_BEGIN',
    VIEW_REVIEW_DETAIL_DATA_SUCCESS : 'VIEW_REVIEW_DETAIL_DATA_SUCCESS',
    VIEW_REVIEW_DETAIL_DATA_ERR : 'VIEW_REVIEW_DETAIL_DATA_ERR',

    REVIEW_HISTORY_DATA_BEGIN : 'REVIEW_HISTORY_DATA_BEGIN',
    REVIEW_HISTORY_DATA_SUCCESS: 'REVIEW_HISTORY_DATA_SUCCESS',
    REVIEW_HISTORY_DATA_ERR : 'REVIEW_HISTORY_DATA_ERR',


    ASSIGN_REVIEW_DATA_BEGIN : 'ASSIGN_REVIEW_DATA_BEGIN',
    ASSIGN_REVIEW_DATA_SUCCESS: 'ASSIGN_REVIEW_DATA_SUCCESS',
    ASSIGN_REVIEW_DATA_ERR : 'ASSIGN_REVIEW_DATA_ERR',
    ASSIGN_REVIEW_DATA_RESET : 'ASSIGN_REVIEW_DATA_RESET',

    UPDATE_REVIEW_DATA_BEGIN : 'UPDATE_REVIEW_DATA_BEGIN',
    UPDATE_REVIEW_DATA_SUCCESS: 'UPDATE_REVIEW_DATA_SUCCESS',
    UPDATE_REVIEW_DATA_ERR : 'UPDATE_REVIEW_DATA_ERR',
    UPDATE_REVIEW_DATA_RESET : 'UPDATE_REVIEW_DATA_RESET',


    
    
    REVIEW_REPORT_DATA_BEGIN : 'REVIEW_REPORT_DATA_BEGIN',
    REVIEW_REPORT_DATA_SUCCESS: 'REVIEW_REPORT_DATA_SUCCESS',
    REVIEW_REPORT_DATA_ERR : 'REVIEW_REPORT_DATA_ERR',

    REVIEW_REPORT_SAVE_DATA_BEGIN : 'REVIEW_REPORT_SAVE_DATA_BEGIN',
    REVIEW_REPORT_SAVE_DATA_SUCCESS: 'REVIEW_REPORT_SAVE_DATA_SUCCESS',
    REVIEW_REPORT_SAVE_DATA_ERR : 'REVIEW_REPORT_SAVE_DATA_ERR',

    REVIEW_REPORT_DOWNLOAD_DATA_BEGIN : 'REVIEW_REPORT_DOWNLOAD_DATA_BEGIN',
    REVIEW_REPORT_DOWNLOAD_DATA_SUCCESS: 'REVIEW_REPORT_DOWNLOAD_DATA_SUCCESS',
    REVIEW_REPORT_DOWNLOAD_DATA_ERR : 'REVIEW_REPORT_DOWNLOAD_DATA_ERR',
   

    reviewDashboardLeadSourceDataBegin: () => {
        return {
            type: actions.REVIEW_DASHBOARD_LEAD_SOURCE_DATA_BEGIN,
        };
    },

    reviewDashboardLeadSourceDataSuccess: (data,start,end) => {
        return {
            type: actions.REVIEW_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    reviewDashboardLeadSourceDataErr: (err) => {
        return {
            type: actions.REVIEW_DASHBOARD_LEAD_SOURCE_DATA_ERR,
            err,
        };
    },

    reviewDashboardRegionDataBegin: () => {
        return {
            type: actions.REVIEW_DASHBOARD_REGION_DATA_BEGIN,
        };
    },

    reviewDashboardRegionDataSuccess: (data,start,end) => {
        return {
            type: actions.REVIEW_DASHBOARD_REGION_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    reviewDashboardRegionDataErr: (err) => {
        return {
            type: actions.REVIEW_DASHBOARD_REGION_DATA_ERR,
            err,
        };
    },

    reviewDashboardCategoryDataBegin: () => {
        return {
            type: actions.REVIEW_DASHBOARD_CATEGORY_DATA_BEGIN,
        };
    },

    reviewDashboardCategoryDataSuccess: (data,start,end) => {
        return {
            type: actions.REVIEW_DASHBOARD_CATEGORY_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    reviewDashboardCategoryDataErr: (err) => {
        return {
            type: actions.REVIEW_DASHBOARD_CATEGORY_DATA_ERR,
            err,
        };
    },


    reviewDashboardTicketDataBegin: () => {
        return {
            type: actions.REVIEW_DASHBOARD_TICKET_DATA_BEGIN,
        };
    },

    reviewDashboardTicketDataSuccess: (data,start,end) => {
        return {
            type: actions.REVIEW_DASHBOARD_TICKET_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    reviewDashboardTicketDataErr: (err) => {
        return {
            type: actions.REVIEW_DASHBOARD_TICKET_DATA_ERR,
            err,
        };
    },

    reviewDashboardSummaryDataBegin: () => {
        return {
            type: actions.REVIEW_DASHBOARD_SUMMARY_DATA_BEGIN,
        };
    },

    reviewDashboardSummaryDataSuccess: (data) => {
        return {
            type: actions.REVIEW_DASHBOARD_SUMMARY_DATA_SUCCESS,
            data,
        };
    },

    reviewDashboardSummaryDataErr: (err) => {
        return {
            type: actions.REVIEW_DASHBOARD_SUMMARY_DATA_ERR,
            err,
        };
    },

    reviewQueriesDataBegin: () => {
        return {
            type: actions.REVIEW_QUERIES_DATA_BEGIN,
        };
    },

    reviewQueriesDataSuccess: (data) => {
        return {
            type: actions.REVIEW_QUERIES_DATA_SUCCESS,
            data,
        };
    },

    reviewQueriesDataErr: (err) => {
        return {
            type: actions.REVIEW_QUERIES_DATA_ERR,
            err,
        };
    },

    reviewDetailsDataBegin: () => {
        return {
            type: actions.VIEW_REVIEW_DETAIL_DATA_BEGIN,
        };
    },

    reviewDetailsDataSuccess: (data) => {
        return {
            type: actions.VIEW_REVIEW_DETAIL_DATA_SUCCESS,
            data,
        };
    },

    reviewDetailsDataErr: (err) => {
        return {
            type: actions.VIEW_REVIEW_DETAIL_DATA_ERR,
            err,
        };
    },

    reviewHistoryDataBegin: () => {
        return {
            type: actions.REVIEW_HISTORY_DATA_BEGIN,
        };
    },

    reviewHistoryDataSuccess: (data) => {
        return {
            type: actions.REVIEW_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    reviewHistoryDataErr: (err) => {
        return {
            type: actions.REVIEW_HISTORY_DATA_ERR,
            err,
        };
    },

    assignReviewDetailsDataBegin: () => {
        return {
            type: actions.ASSIGN_REVIEW_DATA_BEGIN,
        };
    },
    
    assignReviewDetailsDataSuccess: (data) => {
        return {
            type: actions.ASSIGN_REVIEW_DATA_SUCCESS,
            data,
        };
    },
    
    assignReviewDetailsDataErr: (err) => {
        return {
            type: actions.ASSIGN_REVIEW_DATA_ERR,
            err,
        };
    },
    
    assignReviewDetailsDataReset: (err) => {
        return {
            type: actions.ASSIGN_REVIEW_DATA_RESET,
            err,
        };
    },

    updateReviewStatusDataBegin: () => {
        return {
            type: actions.UPDATE_REVIEW_DATA_BEGIN,
        };
    },
    
    updateReviewStatusDataSuccess: (data) => {
        return {
            type: actions.UPDATE_REVIEW_DATA_SUCCESS,
            data,
        };
    },
    
    updateReviewStatusDataErr: (err) => {
        return {
            type: actions.UPDATE_REVIEW_DATA_ERR,
            err,
        };
    },
    
    updateReviewStatusDataReset: (err) => {
        return {
            type: actions.UPDATE_REVIEW_DATA_RESET,
            err,
        };
    },

    reviewReportDataBegin: () => {
        return {
            type: actions.REVIEW_REPORT_DATA_BEGIN,
        };
    },

    reviewReportDataSuccess: (data) => {
        return {
            type: actions.REVIEW_REPORT_DATA_SUCCESS,
            data,
        };
    },

    reviewReportDataErr: (err) => {
        return {
            type: actions.REVIEW_REPORT_DATA_ERR,
            err,
        };
    },

    reviewReportSaveDataBegin: () => {
        return {
            type: actions.REVIEW_REPORT_SAVE_DATA_BEGIN,
        };
    },

    reviewReportSaveDataSuccess: (data) => {
        return {
            type: actions.REVIEW_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    reviewReportSaveDataErr: (err) => {
        return {
            type: actions.REVIEW_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    reviewReportDownloadDataBegin: () => {
        return {
            type: actions.REVIEW_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    reviewReportDownloadDataSuccess: (data) => {
        return {
            type: actions.REVIEW_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    reviewReportDownloadDataErr: (err) => {
        return {
            type: actions.REVIEW_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },
};

export default actions;
