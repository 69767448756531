const actions = {
    
    OUTOFWARRANTY_LIST_DATA_BEGIN   : 'OUTOFWARRANTY_LIST_DATA_BEGIN',
    OUTOFWARRANTY_LIST_DATA_SUCCESS : 'OUTOFWARRANTY_LIST_DATA_SUCCESS',
    OUTOFWARRANTY_LIST_DATA_ERR     : 'OUTOFWARRANTY_LIST_DATA_ERR',

    OUTOFWARRANTY_SCHEDULED_LIST_DATA_BEGIN     : 'OUTOFWARRANTY_SCHEDULED_LIST_DATA_BEGIN',
    OUTOFWARRANTY_SCHEDULED_LIST_DATA_SUCCESS   : 'OUTOFWARRANTY_SCHEDULED_LIST_DATA_SUCCESS',
    OUTOFWARRANTY_SCHEDULED_LIST_DATA_ERR       : 'OUTOFWARRANTY_SCHEDULED_LIST_DATA_ERR',

    ASSIGN_VENDOR_OUTOFWARRANTY_DATA_BEGIN : 'ASSIGN_VENDOR_OUTOFWARRANTY_DATA_BEGIN',
    ASSIGN_VENDOR_OUTOFWARRANTY_DATA_SUCCESS: 'ASSIGN_VENDOR_OUTOFWARRANTY_DATA_SUCCESS',
    ASSIGN_VENDOR_OUTOFWARRANTY_DATA_ERR : 'ASSIGN_VENDOR_OUTOFWARRANTY_DATA_ERR',
    ASSIGN_VENDOR_OUTOFWARRANTY_DATA_RESET : 'ASSIGN_VENDOR_OUTOFWARRANTY_DATA_RESET',

    OUTOFWARRANTY_DISPOSITION_LIST_DATA_BEGIN : 'OUTOFWARRANTY_DISPOSITION_LIST_DATA_BEGIN',
    OUTOFWARRANTY_DISPOSITION_LIST_DATA_SUCCESS : 'OUTOFWARRANTY_DISPOSITION_LIST_DATA_SUCCESS',
    OUTOFWARRANTY_DISPOSITION_LIST_DATA_ERR : 'OUTOFWARRANTY_DISPOSITION_LIST_DATA_ERR',

    OUTOFWARRANTY_VENDOR_LIST_DATA_BEGIN   : 'OUTOFWARRANTY_VENDOR_LIST_DATA_BEGIN',
    OUTOFWARRANTY_VENDOR_LIST_DATA_SUCCESS      : 'OUTOFWARRANTY_VENDOR_LIST_DATA_SUCCESS',
    OUTOFWARRANTY_VENDOR_LIST_DATA_ERR     : 'OUTOFWARRANTY_VENDOR_LIST_DATA_ERR',

    OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_BEGIN     : 'OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_BEGIN',
    OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_SUCCESS   : 'OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_SUCCESS',
    OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_ERR       : 'OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_ERR',

    VENDOR_UPDATE_STATUS_DATA_BEGIN: 'VENDOR_UPDATE_STATUS_DATA_BEGIN',
    VENDOR_UPDATE_STATUS_DATA_SUCCESS: 'VENDOR_UPDATE_STATUS_DATA_SUCCESS',
    VENDOR_UPDATE_STATUS_DATA_ERR: 'VENDOR_UPDATE_STATUS_DATA_ERR',
    VENDOR_UPDATE_STATUS_DATA_RESET: 'VENDOR_UPDATE_STATUS_DATA_RESET',
    
    VIEW_OUTOFWARRANTY_DATA_BEGIN : 'VIEW_OUTOFWARRANTY_DATA_BEGIN',
    VIEW_OUTOFWARRANTY_DATA_SUCCESS: 'VIEW_OUTOFWARRANTY_DATA_SUCCESS',
    VIEW_OUTOFWARRANTY_DATA_ERR : 'VIEW_OUTOFWARRANTY_DATA_ERR',

    OUTOFWARRANTY_HISTORY_DATA_BEGIN : 'OUTOFWARRANTY_HISTORY_DATA_BEGIN',
    OUTOFWARRANTY_HISTORY_DATA_SUCCESS: 'OUTOFWARRANTY_HISTORY_DATA_SUCCESS',
    OUTOFWARRANTY_HISTORY_DATA_ERR : 'OUTOFWARRANTY_HISTORY_DATA_ERR',
   
    OUTOFWARRANTY_REPORT_DATE_BEGIN : 'OUTOFWARRANTY_REPORT_DATE_BEGIN',
    OUTOFWARRANTY_REPORT_DATE_SUCCESS: 'OUTOFWARRANTY_REPORT_DATE_SUCCESS',
    OUTOFWARRANTY_REPORT_DATE_ERR : 'OUTOFWARRANTY_REPORT_DATE_ERR',

    OUTOFWARRANTY_REPORT_SAVE_DATA_BEGIN : 'OUTOFWARRANTY_REPORT_SAVE_DATA_BEGIN',
    OUTOFWARRANTY_REPORT_SAVE_DATA_SUCCESS: 'OUTOFWARRANTY_REPORT_SAVE_DATA_SUCCESS',
    OUTOFWARRANTY_REPORT_SAVE_DATA_ERR : 'OUTOFWARRANTY_REPORT_SAVE_DATA_ERR',

    OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_BEGIN : 'OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_BEGIN',
    OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_SUCCESS: 'OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_SUCCESS',
    OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_ERR : 'OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_ERR',


    outofwarrantyListDataBegin: () => {
        return {
            type: actions.OUTOFWARRANTY_LIST_DATA_BEGIN,
        };
    },

    outofwarrantyListDataSuccess: (data) => {
        return {
            type: actions.OUTOFWARRANTY_LIST_DATA_SUCCESS,
            data,
        };
    },

    outofwarrantyListDataErr: (err) => {
        return {
            type: actions.OUTOFWARRANTY_LIST_DATA_ERR,
            err,
        };
    },

    outofwarrantyScheduledListDataBegin: () => {
        return {
            type: actions.OUTOFWARRANTY_SCHEDULED_LIST_DATA_BEGIN,
        };
    },

    outofwarrantyScheduledListDataSuccess: (data) => {
        return {
            type: actions.OUTOFWARRANTY_SCHEDULED_LIST_DATA_SUCCESS,
            data,
        };
    },

    outofwarrantyScheduledListDataErr: (err) => {
        return {
            type: actions.OUTOFWARRANTY_SCHEDULED_LIST_DATA_ERR,
            err,
        };
    },

    assignVendorOutofWarrantyDataBegin: () => {
        return {
            type: actions.ASSIGN_VENDOR_OUTOFWARRANTY_DATA_BEGIN,
        };
    },

    assignVendorOutofWarrantyDataSuccess: (data) => {
        return {
            type: actions.ASSIGN_VENDOR_OUTOFWARRANTY_DATA_SUCCESS,
            data,
        };
    },

    assignVendorOutofWarrantyDataErr: (err) => {
        return {
            type: actions.ASSIGN_VENDOR_OUTOFWARRANTY_DATA_ERR,
            err,
        };
    },
    
    assignVendorOutofWarrantyDataReset: (err) => {
        return {
            type: actions.ASSIGN_VENDOR_OUTOFWARRANTY_DATA_RESET,
            err,
        };
    },

    outofwarrantyDispositionListDataBegin: () => {
        return {
            type: actions.OUTOFWARRANTY_DISPOSITION_LIST_DATA_BEGIN,
        };
    },

    outofwarrantyDispositionListDataSuccess: (data) => {
        return {
            type: actions.OUTOFWARRANTY_DISPOSITION_LIST_DATA_SUCCESS,
            data,
        };
    },

    outofwarrantyDispositionListDataErr: (err) => {
        return {
            type: actions.OUTOFWARRANTY_DISPOSITION_LIST_DATA_ERR,
            err,
        };
    },

    outofwarrantyVendorListDataBegin: () => {
        return {
            type: actions.OUTOFWARRANTY_VENDOR_LIST_DATA_BEGIN,
        };
    },

    outofwarrantyVendorListDataSuccess: (data) => {
        return {
            type: actions.OUTOFWARRANTY_VENDOR_LIST_DATA_SUCCESS,
            data,
        };
    },

    outofwarrantyVendorListDataErr: (err) => {
        return {
            type: actions.OUTOFWARRANTY_VENDOR_LIST_DATA_ERR,
            err,
        };
    },

    outofwarrantyVendorScheduledListDataBegin: () => {
        return {
            type: actions.OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_BEGIN,
        };
    },

    outofwarrantyVendorScheduledListDataSuccess: (data) => {
        return {
            type: actions.OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_SUCCESS,
            data,
        };
    },

    outofwarrantyVendorScheduledListDataErr: (err) => {
        return {
            type: actions.OUTOFWARRANTY_VENDOR_SCHEDULED_LIST_DATA_ERR,
            err,
        };
    },

    vendorUpdateStatusDataBegin: () => {
        return {
            type: actions.VENDOR_UPDATE_STATUS_DATA_BEGIN,
        };
    },

    vendorUpdateStatusDataSuccess: (data) => {
        return {
            type: actions.VENDOR_UPDATE_STATUS_DATA_SUCCESS,
            data,
        };
    },

    vendorUpdateStatusDataErr: (err) => {
        return {
            type: actions.VENDOR_UPDATE_STATUS_DATA_ERR,
            err,
        };
    },
    
    vendorUpdateStatusDataReset: (err) => {
        return {
            type: actions.VENDOR_UPDATE_STATUS_DATA_RESET,
            err,
        };
    },


    viewOutofwarrantyDataBegin: () => {
        return {
            type: actions.VIEW_OUTOFWARRANTY_DATA_BEGIN,
        };
    },

    viewOutofwarrantyDataSuccess: (data) => {
        return {
            type: actions.VIEW_OUTOFWARRANTY_DATA_SUCCESS,
            data,
        };
    },

    viewOutofwarrantyDataErr: (err) => {
        return {
            type: actions.VIEW_OUTOFWARRANTY_DATA_ERR,
            err,
        };
    },

    outofwarrantyHistoryDataBegin: () => {
        return {
            type: actions.OUTOFWARRANTY_HISTORY_DATA_BEGIN,
        };
    },

    outofwarrantyHistoryDataSuccess: (data) => {
        return {
            type: actions.OUTOFWARRANTY_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    outofwarrantyHistoryDataErr: (err) => {
        return {
            type: actions.OUTOFWARRANTY_HISTORY_DATA_ERR,
            err,
        };
    },
    
    outofwarrantyReportDataBegin: () => {
        return {
            type: actions.OUTOFWARRANTY_REPORT_DATE_BEGIN,
        };
    },

    outofwarrantyReportDataSuccess: (data) => {
        return {
            type: actions.OUTOFWARRANTY_REPORT_DATE_SUCCESS,
            data,
        };
    },

    outofwarrantyReportDataErr: (err) => {
        return {
            type: actions.OUTOFWARRANTY_REPORT_DATE_ERR,
            err,
        };
    },

    outofwarrantyReportSaveDataBegin: () => {
        return {
            type: actions.OUTOFWARRANTY_REPORT_SAVE_DATA_BEGIN,
        };
    },

    outofwarrantyReportSaveDataSuccess: (data) => {
        return {
            type: actions.OUTOFWARRANTY_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    outofwarrantyReportSaveDataErr: (err) => {
        return {
            type: actions.OUTOFWARRANTY_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    outofwarrantyReportDownloadDataBegin: () => {
        return {
            type: actions.OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    outofwarrantyReportDownloadDataSuccess: (data) => {
        return {
            type: actions.OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    outofwarrantyReportDownloadDataErr: (err) => {
        return {
            type: actions.OUTOFWARRANTY_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },
    
};

export default actions;