import actions from './actions';

const { 

    COMPLAINT_LIST_BEGIN, COMPLAINT_LIST_SUCCESS, COMPLAINT_LIST_ERR,

    HAPPY_COMPLAINT_LIST_BEGIN, HAPPY_COMPLAINT_LIST_SUCCESS, HAPPY_COMPLAINT_LIST_ERR,
    GET_PRIORITY_LIST_BEGIN, GET_PRIORITY_LIST_SUCCESS, GET_PRIORITY_LIST_ERR, 
    GET_SCHEDULE_LIST_BEGIN, GET_SCHEDULE_LIST_SUCCESS, GET_SCHEDULE_LIST_ERR,  


    ASSIGN_AGENT_COMPLAINT_DATA_BEGIN, ASSIGN_AGENT_COMPLAINT_DATA_SUCCESS, ASSIGN_AGENT_COMPLAINT_DATA_ERR,ASSIGN_AGENT_COMPLAINT_DATA_RESET,
    UNASSIGN_AGENT_COMPLAINT_DATA_BEGIN, UNASSIGN_AGENT_COMPLAINT_DATA_SUCCESS, UNASSIGN_AGENT_COMPLAINT_DATA_ERR, UNASSIGN_AGENT_COMPLAINT_DATA_RESET,
    COMPLAINT_REGISTER_HISTORY_DATA_BEGIN,COMPLAINT_REGISTER_HISTORY_DATA_SUCCESS,COMPLAINT_REGISTER_HISTORY_DATA_ERR,

    ABANDONED_LIST_DATA_BEGIN ,ABANDONED_LIST_DATA_SUCCESS, ABANDONED_LIST_DATA_ERR,
    ASSIGN_AGENT_ABANDONED_DATA_BEGIN, ASSIGN_AGENT_ABANDONED_DATA_SUCCESS, ASSIGN_AGENT_ABANDONED_DATA_ERR,ASSIGN_AGENT_ABANDONED_DATA_RESET,
    VIEW_ABANDONED_DATA_BEGIN ,VIEW_ABANDONED_DATA_SUCCESS, VIEW_ABANDONED_DATA_ERR,
    ABANDONED_HISTORY_DATA_BEGIN ,ABANDONED_HISTORY_DATA_SUCCESS, ABANDONED_HISTORY_DATA_ERR,

    MANDATORY_LIST_DATA_BEGIN, MANDATORY_LIST_DATA_SUCCESS, MANDATORY_LIST_DATA_ERR,
    VIEW_MANDATORY_DATA_BEGIN, VIEW_MANDATORY_DATA_SUCCESS, VIEW_MANDATORY_DATA_ERR,
    ASSIGN_MANDATORY_DATA_BEGIN, ASSIGN_MANDATORY_DATA_SUCCESS, ASSIGN_MANDATORY_DATA_ERR, ASSIGN_MANDATORY_DATA_RESET,
    MANDATORY_HISTORY_DATA_BEGIN, MANDATORY_HISTORY_DATA_SUCCESS, MANDATORY_HISTORY_DATA_ERR,

    ACCESSORIES_TELE_SALES_LIST_DATA_BEGIN, ACCESSORIES_TELE_SALES_LIST_DATA_SUCCESS, ACCESSORIES_TELE_SALES_LIST_DATA_ERR,
    VIEW_ACCESSORIES_TELE_SALES_DATA_BEGIN, VIEW_ACCESSORIES_TELE_SALES_DATA_SUCCESS, VIEW_ACCESSORIES_TELE_SALES_DATA_ERR,
    ASSIGN_ACCESSORIES_TELE_SALES_DATA_BEGIN, ASSIGN_ACCESSORIES_TELE_SALES_DATA_SUCCESS, ASSIGN_ACCESSORIES_TELE_SALES_DATA_ERR, ASSIGN_ACCESSORIES_TELE_SALES_DATA_RESET,
    ACCESSORIES_TELE_SALES_HISTORY_DATA_BEGIN, ACCESSORIES_TELE_SALES_HISTORY_DATA_SUCCESS, ACCESSORIES_TELE_SALES_HISTORY_DATA_ERR,

    DEMO_HAPPY_LIST_DATA_BEGIN, DEMO_HAPPY_LIST_DATA_SUCCESS, DEMO_HAPPY_LIST_DATA_ERR,
    ASSIGN_DEMO_HAPPY_DATA_BEGIN,  ASSIGN_DEMO_HAPPY_DATA_SUCCESS, ASSIGN_DEMO_HAPPY_DATA_ERR, ASSIGN_DEMO_HAPPY_DATA_RESET,
    VIEW_DEMO_HAPPY_DETAILS_DATA_BEGIN, VIEW_DEMO_HAPPY_DETAILS_DATA_SUCCESS, VIEW_DEMO_HAPPY_DETAILS_DATA_ERR,
    DEMO_HAPPY_HISTORY_DATA_BEGIN, DEMO_HAPPY_HISTORY_DATA_SUCCESS, DEMO_HAPPY_HISTORY_DATA_ERR,
    DEMO_HAPPY_SCHEDULED_LIST_DATA_BEGIN,  DEMO_HAPPY_SCHEDULED_LIST_DATA_SUCCESS, DEMO_HAPPY_SCHEDULED_LIST_DATA_ERR,
    DEMO_HAPPY_PRIORITY_LIST_DATA_BEGIN,  DEMO_HAPPY_PRIORITY_LIST_DATA_SUCCESS, DEMO_HAPPY_PRIORITY_LIST_DATA_ERR,


    EXCEL_HEADER_DATA_BEGIN, EXCEL_HEADER_DATA_SUCCESS, EXCEL_HEADER_DATA_ERR,
    SUBMIT_FILE_DATA_BEGIN, SUBMIT_FILE_DATA_SUCCESS, SUBMIT_FILE_DATA_ERR,
    DATA_DATA_BEGIN, DATA_DATA_SUCCESS, DATA_DATA_ERR,
    DATA_SAVE_DATA_BEGIN, DATA_SAVE_DATA_SUCCESS,DATA_SAVE_DATA_ERR,
    DATA_DOWNLOAD_DATA_BEGIN, DATA_DOWNLOAD_DATA_SUCCESS, DATA_DOWNLOAD_DATA_ERR,

    DEMO_EXCEL_DATA_BEGIN, DEMO_EXCEL_DATA_SUCCESS,DEMO_EXCEL_DATA_ERR,
} = actions;

const initialState = { data: [], loading: false, error: null, };

const requestTypeSubmittedListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case COMPLAINT_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case COMPLAINT_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case COMPLAINT_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}



const happydemoComplaintReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case HAPPY_COMPLAINT_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case HAPPY_COMPLAINT_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case HAPPY_COMPLAINT_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getPriorityListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_PRIORITY_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_PRIORITY_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_PRIORITY_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getScheduleListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_SCHEDULE_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_SCHEDULE_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_SCHEDULE_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}


const assignAgentComplaintReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ASSIGN_AGENT_COMPLAINT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ASSIGN_AGENT_COMPLAINT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ASSIGN_AGENT_COMPLAINT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case ASSIGN_AGENT_COMPLAINT_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const unassignAgentComplaintReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UNASSIGN_AGENT_COMPLAINT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case UNASSIGN_AGENT_COMPLAINT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case UNASSIGN_AGENT_COMPLAINT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case UNASSIGN_AGENT_COMPLAINT_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const complaintRegisterHistoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case COMPLAINT_REGISTER_HISTORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case COMPLAINT_REGISTER_HISTORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case COMPLAINT_REGISTER_HISTORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        
        default:
            return state;
    }
}

const abandonedListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ABANDONED_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ABANDONED_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ABANDONED_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const assignAgentAbandonedReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ASSIGN_AGENT_ABANDONED_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ASSIGN_AGENT_ABANDONED_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ASSIGN_AGENT_ABANDONED_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case ASSIGN_AGENT_ABANDONED_DATA_RESET:
            return initialState;
        default:
            return state;
    }
};

const viewAbandonedReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VIEW_ABANDONED_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case VIEW_ABANDONED_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case VIEW_ABANDONED_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const abandonedHistoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ABANDONED_HISTORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ABANDONED_HISTORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ABANDONED_HISTORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const mandatoryListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case MANDATORY_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case MANDATORY_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case MANDATORY_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const viewMandatoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VIEW_MANDATORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case VIEW_MANDATORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case VIEW_MANDATORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const assignMandatoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ASSIGN_MANDATORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ASSIGN_MANDATORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ASSIGN_MANDATORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        case ASSIGN_MANDATORY_DATA_RESET:
            return initialState;

        default:
            return state;
    }
};

const mandatoryHistoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case MANDATORY_HISTORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case MANDATORY_HISTORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case MANDATORY_HISTORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};


const accessoriesTeleSalesListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ACCESSORIES_TELE_SALES_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ACCESSORIES_TELE_SALES_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ACCESSORIES_TELE_SALES_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const viewAccessoriesTeleSalesReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VIEW_ACCESSORIES_TELE_SALES_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case VIEW_ACCESSORIES_TELE_SALES_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case VIEW_ACCESSORIES_TELE_SALES_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const assignAccessoriesTeleSalesReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ASSIGN_ACCESSORIES_TELE_SALES_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ASSIGN_ACCESSORIES_TELE_SALES_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ASSIGN_ACCESSORIES_TELE_SALES_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        case ASSIGN_ACCESSORIES_TELE_SALES_DATA_RESET:
            return initialState;

        default:
            return state;
    }
};

const accessoriesTeleSalesHistoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ACCESSORIES_TELE_SALES_HISTORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ACCESSORIES_TELE_SALES_HISTORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ACCESSORIES_TELE_SALES_HISTORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};


const demoHappySapListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEMO_HAPPY_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEMO_HAPPY_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEMO_HAPPY_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const viewDemoHappySapReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VIEW_DEMO_HAPPY_DETAILS_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case VIEW_DEMO_HAPPY_DETAILS_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case VIEW_DEMO_HAPPY_DETAILS_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const assignDemoHappySapReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ASSIGN_DEMO_HAPPY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ASSIGN_DEMO_HAPPY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ASSIGN_DEMO_HAPPY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        case ASSIGN_DEMO_HAPPY_DATA_RESET:
            return initialState;

        default:
            return state;
    }
};

const demoHappySapHistoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEMO_HAPPY_HISTORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEMO_HAPPY_HISTORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEMO_HAPPY_HISTORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const demoHappySapScheduledListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEMO_HAPPY_SCHEDULED_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEMO_HAPPY_SCHEDULED_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEMO_HAPPY_SCHEDULED_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const demoHappySapPriorityListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEMO_HAPPY_PRIORITY_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEMO_HAPPY_PRIORITY_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEMO_HAPPY_PRIORITY_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const excelHeaderReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case EXCEL_HEADER_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case EXCEL_HEADER_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case EXCEL_HEADER_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const submitFileReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SUBMIT_FILE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SUBMIT_FILE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SUBMIT_FILE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const demoExcelReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DEMO_EXCEL_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DEMO_EXCEL_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DEMO_EXCEL_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const dataReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DATA_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DATA_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DATA_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const dataReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DATA_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DATA_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DATA_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const dataReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DATA_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DATA_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DATA_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};


export { 
    requestTypeSubmittedListReducers, happydemoComplaintReducers, getPriorityListReducers, getScheduleListReducers,
    assignAgentComplaintReducers, complaintRegisterHistoryReducers,  unassignAgentComplaintReducers,
    abandonedListReducers, assignAgentAbandonedReducers, viewAbandonedReducers, abandonedHistoryReducers,
    mandatoryListReducers, assignMandatoryReducers, viewMandatoryReducers, mandatoryHistoryReducers,
    accessoriesTeleSalesListReducers,viewAccessoriesTeleSalesReducers,assignAccessoriesTeleSalesReducers,accessoriesTeleSalesHistoryReducers,
    demoHappySapListReducers,viewDemoHappySapReducers,assignDemoHappySapReducers,demoHappySapHistoryReducers,demoHappySapScheduledListReducers,demoHappySapPriorityListReducers,
    excelHeaderReducers, submitFileReducers, demoExcelReducers, 
    dataReportReducers, dataReportSaveReducers, dataReportDownloadReducers  
};
