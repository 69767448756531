import React, { useEffect, useState }  from 'react';
import { Row, Col, Table, Alert } from 'antd'; 
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main, TableWrapper } from '../styled';
import { getEmployeeReportDownload } from '../../redux/employee/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';

const DownloadReport = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        if (getEmployeeReportDownload) {
            dispatch(getEmployeeReportDownload());
        }
    }, [dispatch]);

    const employeeDownloadReportData = useSelector((state) => state.employeeReportDownload);
    
    const [tableData,setTableData] = useState([]);

    const columns = [
        {
            title: 'Report Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Report Created Date',
            dataIndex: 'created',
            key: 'created',
            defaultSortOrder: 'descend',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
        },
    ];

    useEffect(() => {
        const dataTable = [];
        if (employeeDownloadReportData.data && employeeDownloadReportData.data.object_list ){
            employeeDownloadReportData.data.object_list.map((value) => {
                const { id, name, status,  file, created } = value;
                return dataTable.push({
                    key: id,
                    name:name,
                    status: status,
                    file: file,
                    created: moment(created).format('lll'),
                    action: (
                        <div className="table-actions">
                            { file && (
                                <a href={file}> <FontAwesomeIcon icon="fa-solid fa-download" /> Download</a>      
                            )}
                        </div>
                    ),
                });
            });
        }
        if(dataTable.length > 0){
            setTableData(dataTable);
        }
    },[employeeDownloadReportData])

    return(
        <>
            <PageHeader ghost title="Available Employee Report"/>
            
            <Main>
                
                <Row gutter={25}>
                    <Col xs={24}>
                        <Cards headless>
                            { employeeDownloadReportData.data?.object_list && employeeDownloadReportData.data?.object_list.length > 0 ?
                                <TableWrapper className="table-responsive">
                                    <Table
                                        dataSource={tableData}
                                        columns={columns}
                                        pagination={{
                                            defaultPageSize: 20,
                                            total: employeeDownloadReportData.data.total_count,
                                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                        }}
                                        // onChange={onChange}
                                    />
                                </TableWrapper>
                            :
                                <Alert message="Error" description="No Record Found" type="error" />
                            }
                        </Cards>
                    </Col>
                </Row>
            </Main>
        </>        
    )
}
export default DownloadReport