const actions = {

    ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_BEGIN : 'ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_BEGIN',
    ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS: 'ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS',
    ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_ERR : 'ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_ERR',

    ESCALATION_DASHBOARD_CATEGORY_DATA_BEGIN : 'ESCALATION_DASHBOARD_CATEGORY_DATA_BEGIN',
    ESCALATION_DASHBOARD_CATEGORY_DATA_SUCCESS: 'ESCALATION_DASHBOARD_CATEGORY_DATA_SUCCESS',
    ESCALATION_DASHBOARD_CATEGORY_DATA_ERR : 'ESCALATION_DASHBOARD_CATEGORY_DATA_ERR',

    ESCALATION_DASHBOARD_TICKET_DATA_BEGIN : 'ESCALATION_DASHBOARD_TICKET_DATA_BEGIN',
    ESCALATION_DASHBOARD_TICKET_DATA_SUCCESS: 'ESCALATION_DASHBOARD_TICKET_DATA_SUCCESS',
    ESCALATION_DASHBOARD_TICKET_DATA_ERR : 'ESCALATION_DASHBOARD_TICKET_DATA_ERR',

    ESCALATION_DASHBOARD_SUMMARY_DATA_BEGIN : 'ESCALATION_DASHBOARD_SUMMARY_DATA_BEGIN',
    ESCALATION_DASHBOARD_SUMMARY_DATA_SUCCESS: 'ESCALATION_DASHBOARD_SUMMARY_DATA_SUCCESS',
    ESCALATION_DASHBOARD_SUMMARY_DATA_ERR : 'ESCALATION_DASHBOARD_SUMMARY_DATA_ERR',

    ESCALATION_DASHBOARD_REGION_DATA_BEGIN : 'ESCALATION_DASHBOARD_REGION_DATA_BEGIN',
    ESCALATION_DASHBOARD_REGION_DATA_SUCCESS: 'ESCALATION_DASHBOARD_REGION_DATA_SUCCESS',
    ESCALATION_DASHBOARD_REGION_DATA_ERR : 'ESCALATION_DASHBOARD_REGION_DATA_ERR',

    ESCALATION_DEPARTMENT_DATA_BEGIN : 'ESCALATION_DEPARTMENT_DATA_BEGIN',
    ESCALATION_DEPARTMENT_DATA_SUCCESS: 'ESCALATION_DEPARTMENT_DATA_SUCCESS',
    ESCALATION_DEPARTMENT_DATA_ERR : 'ESCALATION_DEPARTMENT_DATA_ERR',

    ESCALATION_LIST_DATA_BEGIN : 'ESCALATION_LIST_DATA_BEGIN',
    ESCALATION_LIST_DATA_SUCCESS: 'ESCALATION_LIST_DATA_SUCCESS',
    ESCALATION_LIST_DATA_ERR : 'ESCALATION_LIST_DATA_ERR',

    ESCALATION_LEAD_ASSIGN_DATA_BEGIN : 'ESCALATION_LEAD_ASSIGN_DATA_BEGIN', 
    ESCALATION_LEAD_ASSIGN_DATA_SUCCESS : 'ESCALATION_LEAD_ASSIGN_DATA_SUCCESS',
    ESCALATION_LEAD_ASSIGN_DATA_ERR : 'ESCALATION_LEAD_ASSIGN_DATA_ERR',
    ESCALATION_LEAD_ASSIGN_DATA_RESET :"ESCALATION_LEAD_ASSIGN_DATA_RESET",

    GET_AGENT_BASED_ESCALATION_LIST_DATA_BEGIN : 'GET_AGENT_BASED_ESCALATION_LIST_DATA_BEGIN',
    GET_AGENT_BASED_ESCALATION_LIST_DATA_SUCCESS : 'GET_AGENT_BASED_ESCALATION_LIST_DATA_SUCCESS',
    GET_AGENT_BASED_ESCALATION_LIST_DATA_ERR : 'GET_AGENT_BASED_ESCALATION_LIST_DATA_ERR',
    
    UPDATE_ESCALATION_STATUS_DATA_BEGIN:'UPDATE_ESCALATION_STATUS_DATA_BEGIN',
    UPDATE_ESCALATION_STATUS_DATA_SUCCESS:'UPDATE_ESCALATION_STATUS_DATA_SUCCESS',
    UPDATE_ESCALATION_STATUS_DATA_ERR:'UPDATE_ESCALATION_STATUS_DATA_ERR',
    UPDATE_ESCALATION_STATUS_DATA_RESET:'UPDATE_ESCALATION_STATUS_DATA_RESET',

    VIEW_ESCALATION_DETAILS_DATA_BEGIN: 'VIEW_ESCALATION_DETAILS_DATA_BEGIN',
    VIEW_ESCALATION_DETAILS_DATA_SUCCESS: 'VIEW_ESCALATION_DETAILS_DATA_SUCCESS',
    VIEW_ESCALATION_DETAILS_DATA_ERR: 'VIEW_ESCALATION_DETAILS_DATA_ERR',

    GET_ESCALATION_STATUS_HISTORY_DATA_BEGIN: 'GET_ESCALATION_STATUS_HISTORY_DATA_BEGIN',
    GET_ESCALATION_STATUS_HISTORY_DATA_SUCCESS: 'GET_ESCALATION_STATUS_HISTORY_DATA_SUCCESS',
    GET_ESCALATION_STATUS_HISTORY_DATA_ERR: 'GET_ESCALATION_STATUS_HISTORY_DATA_ERR',

    ESCALATION_REPORT_DATA_BEGIN : 'ESCALATION_REPORT_DATA_BEGIN',
    ESCALATION_REPORT_DATA_SUCCESS: 'ESCALATION_REPORT_DATA_SUCCESS',
    ESCALATION_REPORT_DATA_ERR : 'ESCALATION_REPORT_DATA_ERR',

    ESCALATION_REPORT_SAVE_DATA_BEGIN : 'ESCALATION_REPORT_SAVE_DATA_BEGIN',
    ESCALATION_REPORT_SAVE_DATA_SUCCESS: 'ESCALATION_REPORT_SAVE_DATA_SUCCESS',
    ESCALATION_REPORT_SAVE_DATA_ERR : 'ESCALATION_REPORT_SAVE_DATA_ERR',

    ESCALATION_REPORT_DOWNLOAD_DATA_BEGIN : 'ESCALATION_REPORT_DOWNLOAD_DATA_BEGIN',
    ESCALATION_REPORT_DOWNLOAD_DATA_SUCCESS: 'ESCALATION_REPORT_DOWNLOAD_DATA_SUCCESS',
    ESCALATION_REPORT_DOWNLOAD_DATA_ERR : 'ESCALATION_REPORT_DOWNLOAD_DATA_ERR',


    escalationDashboardLeadSourceDataBegin: () => {
        return {
            type: actions.ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_BEGIN,
        };
    },

    escalationDashboardLeadSourceDataSuccess: (data,start,end) => {
        return {
            type: actions.ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    escalationDashboardLeadSourceDataErr: (err) => {
        return {
            type: actions.ESCALATION_DASHBOARD_LEAD_SOURCE_DATA_ERR,
            err,
        };
    },

    escalationDashboardRegionDataBegin: () => {
        return {
            type: actions.ESCALATION_DASHBOARD_REGION_DATA_BEGIN,
        };
    },

    escalationDashboardRegionDataSuccess: (data,start,end) => {
        return {
            type: actions.ESCALATION_DASHBOARD_REGION_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    escalationDashboardRegionDataErr: (err) => {
        return {
            type: actions.ESCALATION_DASHBOARD_REGION_DATA_ERR,
            err,
        };
    },

    escalationDashboardCategoryDataBegin: () => {
        return {
            type: actions.ESCALATION_DASHBOARD_CATEGORY_DATA_BEGIN,
        };
    },

    escalationDashboardCategoryDataSuccess: (data,start,end) => {
        return {
            type: actions.ESCALATION_DASHBOARD_CATEGORY_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    escalationDashboardCategoryDataErr: (err) => {
        return {
            type: actions.ESCALATION_DASHBOARD_CATEGORY_DATA_ERR,
            err,
        };
    },

    escalationDashboardTicketDataBegin: () => {
        return {
            type: actions.ESCALATION_DASHBOARD_TICKET_DATA_BEGIN,
        };
    },

    escalationDashboardTicketDataSuccess: (data,start,end) => {
        return {
            type: actions.ESCALATION_DASHBOARD_TICKET_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    escalationDashboardTicketDataErr: (err) => {
        return {
            type: actions.ESCALATION_DASHBOARD_TICKET_DATA_ERR,
            err,
        };
    },

    escalationDashboardSummaryDataBegin: () => {
        return {
            type: actions.ESCALATION_DASHBOARD_SUMMARY_DATA_BEGIN,
        };
    },

    escalationDashboardSummaryDataSuccess: (data) => {
        return {
            type: actions.ESCALATION_DASHBOARD_SUMMARY_DATA_SUCCESS,
            data,
        };
    },

    escalationDashboardSummaryDataErr: (err) => {
        return {
            type: actions.ESCALATION_DASHBOARD_SUMMARY_DATA_ERR,
            err,
        };
    },

    escalationDepartmentDataBegin: () => {
        return {
            type: actions.ESCALATION_DEPARTMENT_DATA_BEGIN,
        };
    },

    escalationDepartmentDataSuccess: (data) => {
        return {
            type: actions.ESCALATION_DEPARTMENT_DATA_SUCCESS,
            data,
        };
    },

    escalationDepartmentDataErr: (err) => {
        return {
            type: actions.ESCALATION_DEPARTMENT_DATA_ERR,
            err,
        };
    },

    escalationListDataBegin: () => {
        return {
            type: actions.ESCALATION_LIST_DATA_BEGIN,
        };
    },

    escalationListDataSuccess: (data) => {
        return {
            type: actions.ESCALATION_LIST_DATA_SUCCESS,
            data,
        };
    },

    escalationListDataErr: (err) => {
        return {
            type: actions.ESCALATION_LIST_DATA_ERR,
            err,
        };
    },

    assignAgentEscalationDataBegin: () => {
        return {
            type: actions.ESCALATION_LEAD_ASSIGN_DATA_BEGIN,
        };
    },

    assignAgentEscalationDataSuccess: (data) => {
        return {
            type: actions.ESCALATION_LEAD_ASSIGN_DATA_SUCCESS,
            data,
        };
    },

    assignAgentEscalationDataErr: (err) => {
        return {
            type: actions.ESCALATION_LEAD_ASSIGN_DATA_ERR,
            err,
        };
    },

    assignAgentEscalationDataReset: (err) => {
        return {
            type: actions.ESCALATION_LEAD_ASSIGN_DATA_RESET,
            err,
        };
    },

    

    getAgentBasedEscalationListDataBegin: () => {
        return {
            type: actions.GET_AGENT_BASED_ESCALATION_LIST_DATA_BEGIN,
        };
    },

    getAgentBasedEscalationListDataSuccess: (data) => {
        return {
            type: actions.GET_AGENT_BASED_ESCALATION_LIST_DATA_SUCCESS,
            data,
        };
    },

    getAgentBasedEscalationListDataErr: (err) => {
        return {
            type: actions.GET_AGENT_BASED_ESCALATION_LIST_DATA_ERR,
            err,
        };
    },
    
    updateEscalationStatusDataBegin: () => {
        return {
            type: actions.UPDATE_ESCALATION_STATUS_DATA_BEGIN,
        };
    },

    updateEscalationStatusDataSuccess: (data) => {
        return {
            type: actions.UPDATE_ESCALATION_STATUS_DATA_SUCCESS,
            data,
        };
    },

    updateEscalationStatusDataErr: (err) => {
        return {
            type: actions.UPDATE_ESCALATION_STATUS_DATA_ERR,
            err,
        };
    },

    updateEscalationStatusDataReset: () => {
        return {
            type: actions.UPDATE_ESCALATION_STATUS_DATA_RESET,
        };
    },

    viewEscalationDetailsDataBegin: () => {
        return {
            type: actions.VIEW_ESCALATION_DETAILS_DATA_BEGIN,
        };
    },
    
    viewEscalationDetailsDataSuccess: (data) => {
        return {
            type: actions.VIEW_ESCALATION_DETAILS_DATA_SUCCESS,
            data,
        };
    },
    
    viewEscalationDetailsDataErr: (err) => {
        return {
            type: actions.VIEW_ESCALATION_DETAILS_DATA_ERR,
            err,
        };
    },
    
    getEscalationStatusHistoryDataBegin: () => {
        return {
            type: actions.GET_ESCALATION_STATUS_HISTORY_DATA_BEGIN,
        };
    },

    getEscalationStatusHistoryDataSuccess: (data) => {
        return {
            type: actions.GET_ESCALATION_STATUS_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    getEscalationStatusHistoryDataErr: (err) => {
        return {
            type: actions.GET_ESCALATION_STATUS_HISTORY_DATA_ERR,
            err,
        };
    },

    escalationReportDataBegin: () => {
        return {
            type: actions.ESCALATION_REPORT_DATA_BEGIN,
        };
    },

    escalationReportDataSuccess: (data) => {
        return {
            type: actions.ESCALATION_REPORT_DATA_SUCCESS,
            data,
        };
    },

    escalationReportDataErr: (err) => {
        return {
            type: actions.ESCALATION_REPORT_DATA_ERR,
            err,
        };
    },

    escalationReportSaveDataBegin: () => {
        return {
            type: actions.ESCALATION_REPORT_SAVE_DATA_BEGIN,
        };
    },

    escalationReportSaveDataSuccess: (data) => {
        return {
            type: actions.ESCALATION_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    escalationReportSaveDataErr: (err) => {
        return {
            type: actions.ESCALATION_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    escalationReportDownloadDataBegin: () => {
        return {
            type: actions.ESCALATION_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    escalationReportDownloadDataSuccess: (data) => {
        return {
            type: actions.ESCALATION_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    escalationReportDownloadDataErr: (err) => {
        return {
            type: actions.ESCALATION_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },

};

export default actions;
