const actions = {

    MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN : 'MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN',
    MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS: 'MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS',
    MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_ERR : 'MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_ERR',

    MAINTENANCE_DASHBOARD_CATEGORY_DATA_BEGIN : 'MAINTENANCE_DASHBOARD_CATEGORY_DATA_BEGIN',
    MAINTENANCE_DASHBOARD_CATEGORY_DATA_SUCCESS: 'MAINTENANCE_DASHBOARD_CATEGORY_DATA_SUCCESS',
    MAINTENANCE_DASHBOARD_CATEGORY_DATA_ERR : 'MAINTENANCE_DASHBOARD_CATEGORY_DATA_ERR',

    MAINTENANCE_DASHBOARD_TICKET_DATA_BEGIN : 'MAINTENANCE_DASHBOARD_TICKET_DATA_BEGIN',
    MAINTENANCE_DASHBOARD_TICKET_DATA_SUCCESS: 'MAINTENANCE_DASHBOARD_TICKET_DATA_SUCCESS',
    MAINTENANCE_DASHBOARD_TICKET_DATA_ERR : 'MAINTENANCE_DASHBOARD_TICKET_DATA_ERR',

    MAINTENANCE_DASHBOARD_SUMMARY_DATA_BEGIN : 'MAINTENANCE_DASHBOARD_SUMMARY_DATA_BEGIN',
    MAINTENANCE_DASHBOARD_SUMMARY_DATA_SUCCESS: 'MAINTENANCE_DASHBOARD_SUMMARY_DATA_SUCCESS',
    MAINTENANCE_DASHBOARD_SUMMARY_DATA_ERR : 'MAINTENANCE_DASHBOARD_SUMMARY_DATA_ERR',

    MAINTENANCE_DASHBOARD_REGION_DATA_BEGIN : 'MAINTENANCE_DASHBOARD_REGION_DATA_BEGIN',
    MAINTENANCE_DASHBOARD_REGION_DATA_SUCCESS: 'MAINTENANCE_DASHBOARD_REGION_DATA_SUCCESS',
    MAINTENANCE_DASHBOARD_REGION_DATA_ERR : 'MAINTENANCE_DASHBOARD_REGION_DATA_ERR',

    MAINTENANCE_DEPARTMENT_DATA_BEGIN : 'MAINTENANCE_DEPARTMENT_DATA_BEGIN',
    MAINTENANCE_DEPARTMENT_DATA_SUCCESS: 'MAINTENANCE_DEPARTMENT_DATA_SUCCESS',
    MAINTENANCE_DEPARTMENT_DATA_ERR : 'MAINTENANCE_DEPARTMENT_DATA_ERR',

    MAINTENANCE_LIST_DATA_BEGIN : 'MAINTENANCE_LIST_DATA_BEGIN',
    MAINTENANCE_LIST_DATA_SUCCESS: 'MAINTENANCE_LIST_DATA_SUCCESS',
    MAINTENANCE_LIST_DATA_ERR : 'MAINTENANCE_LIST_DATA_ERR',

    MAINTENANCE_LEAD_ASSIGN_DATA_BEGIN : 'MAINTENANCE_LEAD_ASSIGN_DATA_BEGIN', 
    MAINTENANCE_LEAD_ASSIGN_DATA_SUCCESS : 'MAINTENANCE_LEAD_ASSIGN_DATA_SUCCESS',
    MAINTENANCE_LEAD_ASSIGN_DATA_ERR : 'MAINTENANCE_LEAD_ASSIGN_DATA_ERR',
    MAINTENANCE_LEAD_ASSIGN_DATA_RESET :"MAINTENANCE_LEAD_ASSIGN_DATA_RESET",

    GET_ASSIGNED_MAINTENANCE_LIST_DATA_BEGIN : 'GET_ASSIGNED_MAINTENANCE_LIST_DATA_BEGIN',
    GET_ASSIGNED_MAINTENANCE_LIST_DATA_SUCCESS : 'GET_ASSIGNED_MAINTENANCE_LIST_DATA_SUCCESS',
    GET_ASSIGNED_MAINTENANCE_LIST_DATA_ERR : 'GET_ASSIGNED_MAINTENANCE_LIST_DATA_ERR',
    
    UPDATE_MAINTENANCE_STATUS_DATA_BEGIN:'UPDATE_MAINTENANCE_STATUS_DATA_BEGIN',
    UPDATE_MAINTENANCE_STATUS_DATA_SUCCESS:'UPDATE_MAINTENANCE_STATUS_DATA_SUCCESS',
    UPDATE_MAINTENANCE_STATUS_DATA_ERR:'UPDATE_MAINTENANCE_STATUS_DATA_ERR',
    UPDATE_MAINTENANCE_STATUS_DATA_RESET:'UPDATE_MAINTENANCE_STATUS_DATA_RESET',

    GET_MAINTENANCE_STATUS_HISTORY_DATA_BEGIN: 'GET_MAINTENANCE_STATUS_HISTORY_DATA_BEGIN',
    GET_MAINTENANCE_STATUS_HISTORY_DATA_SUCCESS: 'GET_MAINTENANCE_STATUS_HISTORY_DATA_SUCCESS',
    GET_MAINTENANCE_STATUS_HISTORY_DATA_ERR: 'GET_MAINTENANCE_STATUS_HISTORY_DATA_ERR',

    MAINTENANCE_REPORT_DATA_BEGIN : 'MAINTENANCE_REPORT_DATA_BEGIN',
    MAINTENANCE_REPORT_DATA_SUCCESS: 'MAINTENANCE_REPORT_DATA_SUCCESS',
    MAINTENANCE_REPORT_DATA_ERR : 'MAINTENANCE_REPORT_DATA_ERR',

    MAINTENANCE_REPORT_SAVE_DATA_BEGIN : 'MAINTENANCE_REPORT_SAVE_DATA_BEGIN',
    MAINTENANCE_REPORT_SAVE_DATA_SUCCESS: 'MAINTENANCE_REPORT_SAVE_DATA_SUCCESS',
    MAINTENANCE_REPORT_SAVE_DATA_ERR : 'MAINTENANCE_REPORT_SAVE_DATA_ERR',

    MAINTENANCE_REPORT_DOWNLOAD_DATA_BEGIN : 'MAINTENANCE_REPORT_DOWNLOAD_DATA_BEGIN',
    MAINTENANCE_REPORT_DOWNLOAD_DATA_SUCCESS: 'MAINTENANCE_REPORT_DOWNLOAD_DATA_SUCCESS',
    MAINTENANCE_REPORT_DOWNLOAD_DATA_ERR : 'MAINTENANCE_REPORT_DOWNLOAD_DATA_ERR',


    MAINTENANCE_EMPLOYEE_REPORT_DATA_BEGIN : 'MAINTENANCE_EMPLOYEE_REPORT_DATA_BEGIN',
    MAINTENANCE_EMPLOYEE_REPORT_DATA_SUCCESS: 'MAINTENANCE_EMPLOYEE_REPORT_DATA_SUCCESS',
    MAINTENANCE_EMPLOYEE_REPORT_DATA_ERR : 'MAINTENANCE_EMPLOYEE_REPORT_DATA_ERR',

    MAINTENANCE_EMPLOYEE_REPORT_SAVE_DATA_BEGIN : 'MAINTENANCE_EMPLOYEE_REPORT_SAVE_DATA_BEGIN',
    MAINTENANCE_EMPLOYEE_REPORT_SAVE_DATA_SUCCESS: 'MAINTENANCE_EMPLOYEE_REPORT_SAVE_DATA_SUCCESS',
    MAINTENANCE_EMPLOYEE_REPORT_SAVE_DATA_ERR : 'MAINTENANCE_EMPLOYEE_REPORT_SAVE_DATA_ERR',

    MAINTENANCE_EMPLOYEE_REPORT_DOWNLOAD_DATA_BEGIN : 'MAINTENANCE_EMPLOYEE_REPORT_DOWNLOAD_DATA_BEGIN',
    MAINTENANCE_EMPLOYEE_REPORT_DOWNLOAD_DATA_SUCCESS: 'MAINTENANCE_EMPLOYEE_REPORT_DOWNLOAD_DATA_SUCCESS',
    MAINTENANCE_EMPLOYEE_REPORT_DOWNLOAD_DATA_ERR : 'MAINTENANCE_EMPLOYEE_REPORT_DOWNLOAD_DATA_ERR',


    maintenanceDashboardLeadSourceDataBegin: () => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_BEGIN,
        };
    },

    maintenanceDashboardLeadSourceDataSuccess: (data,start,end) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    maintenanceDashboardLeadSourceDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_LEAD_SOURCE_DATA_ERR,
            err,
        };
    },

    maintenanceDashboardRegionDataBegin: () => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_REGION_DATA_BEGIN,
        };
    },

    maintenanceDashboardRegionDataSuccess: (data,start,end) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_REGION_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    maintenanceDashboardRegionDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_REGION_DATA_ERR,
            err,
        };
    },

    maintenanceDashboardCategoryDataBegin: () => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_CATEGORY_DATA_BEGIN,
        };
    },

    maintenanceDashboardCategoryDataSuccess: (data,start,end) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_CATEGORY_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    maintenanceDashboardCategoryDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_CATEGORY_DATA_ERR,
            err,
        };
    },


    maintenanceDashboardTicketDataBegin: () => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_TICKET_DATA_BEGIN,
        };
    },

    maintenanceDashboardTicketDataSuccess: (data,start,end) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_TICKET_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    maintenanceDashboardTicketDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_TICKET_DATA_ERR,
            err,
        };
    },

    maintenanceDashboardSummaryDataBegin: () => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_SUMMARY_DATA_BEGIN,
        };
    },

    maintenanceDashboardSummaryDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_SUMMARY_DATA_SUCCESS,
            data,
        };
    },

    maintenanceDashboardSummaryDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_DASHBOARD_SUMMARY_DATA_ERR,
            err,
        };
    },

    maintenanceDepartmentDataBegin: () => {
        return {
            type: actions.MAINTENANCE_DEPARTMENT_DATA_BEGIN,
        };
    },

    maintenanceDepartmentDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_DEPARTMENT_DATA_SUCCESS,
            data,
        };
    },

    maintenanceDepartmentDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_DEPARTMENT_DATA_ERR,
            err,
        };
    },

    maintenanceListDataBegin: () => {
        return {
            type: actions.MAINTENANCE_LIST_DATA_BEGIN,
        };
    },

    maintenanceListDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_LIST_DATA_SUCCESS,
            data,
        };
    },

    maintenanceListDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_LIST_DATA_ERR,
            err,
        };
    },

    maintenanceLeadAssignDataBegin: () => {
        return {
            type: actions.MAINTENANCE_LEAD_ASSIGN_DATA_BEGIN,
        };
    },

    maintenanceLeadAssignDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_LEAD_ASSIGN_DATA_SUCCESS,
            data,
        };
    },

    maintenanceLeadAssignDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_LEAD_ASSIGN_DATA_ERR,
            err,
        };
    },

    maintenanceLeadAssignDataReset: (err) => {
        return {
            type: actions.MAINTENANCE_LEAD_ASSIGN_DATA_RESET,
            err,
        };
    },

    

    getAssignedMaintenanceListDataBegin: () => {
        return {
            type: actions.GET_ASSIGNED_MAINTENANCE_LIST_DATA_BEGIN,
        };
    },

    getAssignedMaintenanceListDataSuccess: (data) => {
        return {
            type: actions.GET_ASSIGNED_MAINTENANCE_LIST_DATA_SUCCESS,
            data,
        };
    },

    getAssignedMaintenanceListDataErr: (err) => {
        return {
            type: actions.GET_ASSIGNED_MAINTENANCE_LIST_DATA_ERR,
            err,
        };
    },
    
    updateMaintenanceStatusDataBegin: () => {
        return {
            type: actions.UPDATE_MAINTENANCE_STATUS_DATA_BEGIN,
        };
    },

    updateMaintenanceStatusDataSuccess: (data) => {
        return {
            type: actions.UPDATE_MAINTENANCE_STATUS_DATA_SUCCESS,
            data,
        };
    },

    updateMaintenanceStatusDataErr: (err) => {
        return {
            type: actions.UPDATE_MAINTENANCE_STATUS_DATA_ERR,
            err,
        };
    },

    updateMaintenanceStatusDataReset: () => {
        return {
            type: actions.UPDATE_MAINTENANCE_STATUS_DATA_RESET,
        };
    },
    
    getMaintenanceStatusHistoryDataBegin: () => {
        return {
            type: actions.GET_MAINTENANCE_STATUS_HISTORY_DATA_BEGIN,
        };
    },

    getMaintenanceStatusHistoryDataSuccess: (data) => {
        return {
            type: actions.GET_MAINTENANCE_STATUS_HISTORY_DATA_SUCCESS,
            data,
        };
    },

    getMaintenanceStatusHistoryDataErr: (err) => {
        return {
            type: actions.GET_MAINTENANCE_STATUS_HISTORY_DATA_ERR,
            err,
        };
    },

    maintenanceReportDataBegin: () => {
        return {
            type: actions.MAINTENANCE_REPORT_DATA_BEGIN,
        };
    },

    maintenanceReportDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_REPORT_DATA_SUCCESS,
            data,
        };
    },

    maintenanceReportDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_REPORT_DATA_ERR,
            err,
        };
    },

    maintenanceReportSaveDataBegin: () => {
        return {
            type: actions.MAINTENANCE_REPORT_SAVE_DATA_BEGIN,
        };
    },

    maintenanceReportSaveDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    maintenanceReportSaveDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    maintenanceReportDownloadDataBegin: () => {
        return {
            type: actions.MAINTENANCE_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    maintenanceReportDownloadDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    maintenanceReportDownloadDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },

    maintenanceEmployeeReportDataBegin: () => {
        return {
            type: actions.MAINTENANCE_EMPLOYEE_REPORT_DATA_BEGIN,
        };
    },

    maintenanceEmployeeReportDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_EMPLOYEE_REPORT_DATA_SUCCESS,
            data,
        };
    },

    maintenanceEmployeeReportDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_EMPLOYEE_REPORT_DATA_ERR,
            err,
        };
    },

    maintenanceEmployeeReportSaveDataBegin: () => {
        return {
            type: actions.MAINTENANCE_EMPLOYEE_REPORT_SAVE_DATA_BEGIN,
        };
    },

    maintenanceEmployeeReportSaveDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_EMPLOYEE_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    maintenanceEmployeeReportSaveDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_EMPLOYEE_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    maintenanceEmployeeReportDownloadDataBegin: () => {
        return {
            type: actions.MAINTENANCE_EMPLOYEE_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    maintenanceEmployeeReportDownloadDataSuccess: (data) => {
        return {
            type: actions.MAINTENANCE_EMPLOYEE_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    maintenanceEmployeeReportDownloadDataErr: (err) => {
        return {
            type: actions.MAINTENANCE_EMPLOYEE_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },

    

};

export default actions;
