import React, { lazy } from 'react';
import { Switch, Route , Redirect, useRouteMatch } from 'react-router-dom';
import withDashboardLayout from '../../layout/withDashboardLayout';
const Dashboard = lazy(() => import('../../container/dashboard'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function DashboardRoutes({...props}) {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path} render={() => <Dashboard {...props} />}  />
            <Route exact path="*" component={NotFound} />
        </Switch>
    );
}
export default withDashboardLayout(DashboardRoutes);