import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch, Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard         = lazy(() => import('../../container/quality/Dashboard'));
const RegisteredList    = lazy(() => import('../../container/quality/RegisteredList'));
const CompletedList     = lazy(() => import('../../container/quality/CompletedList'));
const ViewDetails       = lazy(() => import('../../container/quality/ViewDetails'));
const Report            = lazy(() => import('../../container/quality/Report'));
const DownloadReport    = lazy(() => import('../../container/quality/DownloadReport'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function MaintenanceRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />

            <Route exact path={`${path}/list/:id`} component={RegisteredList} />
            <Route exact path={`${path}/list/:id/view`} component={ViewDetails} />
            
            <Route exact path={`${path}/completed/:id`} component={CompletedList} />
            <Route exact path={`${path}/completed/:id/view`} component={ViewDetails} />
            
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(MaintenanceRoutes);
