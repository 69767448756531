const actions = {

    QUALITY_DASHBOARD_LEAD_SOURCE_DATA_BEGIN : 'QUALITY_DASHBOARD_LEAD_SOURCE_DATA_BEGIN',
    QUALITY_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS: 'QUALITY_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS',
    QUALITY_DASHBOARD_LEAD_SOURCE_DATA_ERR : 'QUALITY_DASHBOARD_LEAD_SOURCE_DATA_ERR',

    QUALITY_DASHBOARD_CATEGORY_DATA_BEGIN : 'QUALITY_DASHBOARD_CATEGORY_DATA_BEGIN',
    QUALITY_DASHBOARD_CATEGORY_DATA_SUCCESS: 'QUALITY_DASHBOARD_CATEGORY_DATA_SUCCESS',
    QUALITY_DASHBOARD_CATEGORY_DATA_ERR : 'QUALITY_DASHBOARD_CATEGORY_DATA_ERR',

    QUALITY_DASHBOARD_TICKET_DATA_BEGIN : 'QUALITY_DASHBOARD_TICKET_DATA_BEGIN',
    QUALITY_DASHBOARD_TICKET_DATA_SUCCESS: 'QUALITY_DASHBOARD_TICKET_DATA_SUCCESS',
    QUALITY_DASHBOARD_TICKET_DATA_ERR : 'QUALITY_DASHBOARD_TICKET_DATA_ERR',

    QUALITY_DASHBOARD_SUMMARY_DATA_BEGIN : 'QUALITY_DASHBOARD_SUMMARY_DATA_BEGIN',
    QUALITY_DASHBOARD_SUMMARY_DATA_SUCCESS: 'QUALITY_DASHBOARD_SUMMARY_DATA_SUCCESS',
    QUALITY_DASHBOARD_SUMMARY_DATA_ERR : 'QUALITY_DASHBOARD_SUMMARY_DATA_ERR',

    QUALITY_DASHBOARD_REGION_DATA_BEGIN : 'QUALITY_DASHBOARD_REGION_DATA_BEGIN',
    QUALITY_DASHBOARD_REGION_DATA_SUCCESS: 'QUALITY_DASHBOARD_REGION_DATA_SUCCESS',
    QUALITY_DASHBOARD_REGION_DATA_ERR : 'QUALITY_DASHBOARD_REGION_DATA_ERR',
    
    QUALITY_FORM_DATA_BEGIN : 'QUALITY_FORM_DATA_BEGIN',
    QUALITY_FORM_DATA_SUCCESS: 'QUALITY_FORM_DATA_SUCCESS',
    QUALITY_FORM_DATA_ERR : 'QUALITY_FORM_DATA_ERR',

    QUALITY_FORM_SUBMIT_DATA_BEGIN : 'QUALITY_FORM_SUBMIT_DATA_BEGIN', 
    QUALITY_FORM_SUBMIT_DATA_SUCCESS: 'QUALITY_FORM_SUBMIT_DATA_SUCCESS', 
    QUALITY_FORM_SUBMIT_DATA_ERR : 'QUALITY_FORM_SUBMIT_DATA_ERR', 
    QUALITY_FORM_SUBMIT_DATA_RESET: 'QUALITY_FORM_SUBMIT_DATA_RESET',

    GET_AUDIT_QUALITY_LIST_DATA_BEGIN: 'GET_AUDIT_QUALITY_LIST_DATA_BEGIN',
    GET_AUDIT_QUALITY_LIST_DATA_SUCCESS: 'GET_AUDIT_QUALITY_LIST_DATA_SUCCESS',
    GET_AUDIT_QUALITY_LIST_DATA_ERR: 'GET_AUDIT_QUALITY_LIST_DATA_ERR',
    
    SUBMIT_QUALITY_SHARE_DATA_BEGIN: 'SUBMIT_QUALITY_SHARE_DATA_BEGIN',
    SUBMIT_QUALITY_SHARE_DATA_SUCCESS: 'SUBMIT_QUALITY_SHARE_DATA_SUCCESS',
    SUBMIT_QUALITY_SHARE_DATA_ERR: 'SUBMIT_QUALITY_SHARE_DATA_ERR',
    SUBMIT_QUALITY_SHARE_DATA_RESET: 'SUBMIT_QUALITY_SHARE_DATA_RESET',

    GET_AGENT_QUALITY_LIST_DATA_BEGIN:'GET_AGENT_QUALITY_LIST_DATA_BEGIN',
    GET_AGENT_QUALITY_LIST_DATA_SUCCESS:'GET_AGENT_QUALITY_LIST_DATA_SUCCESS',
    GET_AGENT_QUALITY_LIST_DATA_ERR:'GET_AGENT_QUALITY_LIST_DATA_ERR',
    

    VIEW_AUDIT_QUALITY_DATA_BEGIN: 'VIEW_AUDIT_QUALITY_DATA_BEGIN', 
    VIEW_AUDIT_QUALITY_DATA_SUCCESS: 'VIEW_AUDIT_QUALITY_DATA_SUCCESS', 
    VIEW_AUDIT_QUALITY_DATA_ERR: 'VIEW_AUDIT_QUALITY_DATA_ERR',


    QUALITY_REPORT_DATA_BEGIN : 'QUALITY_REPORT_DATA_BEGIN',
    QUALITY_REPORT_DATA_SUCCESS: 'QUALITY_REPORT_DATA_SUCCESS',
    QUALITY_REPORT_DATA_ERR : 'QUALITY_REPORT_DATA_ERR',

    QUALITY_REPORT_SAVE_DATA_BEGIN : 'QUALITY_REPORT_SAVE_DATA_BEGIN',
    QUALITY_REPORT_SAVE_DATA_SUCCESS: 'QUALITY_REPORT_SAVE_DATA_SUCCESS',
    QUALITY_REPORT_SAVE_DATA_ERR : 'QUALITY_REPORT_SAVE_DATA_ERR',

    QUALITY_REPORT_DOWNLOAD_DATA_BEGIN : 'QUALITY_REPORT_DOWNLOAD_DATA_BEGIN',
    QUALITY_REPORT_DOWNLOAD_DATA_SUCCESS: 'QUALITY_REPORT_DOWNLOAD_DATA_SUCCESS',
    QUALITY_REPORT_DOWNLOAD_DATA_ERR : 'QUALITY_REPORT_DOWNLOAD_DATA_ERR',
   

    qualityDashboardLeadSourceDataBegin: () => {
        return {
            type: actions.QUALITY_DASHBOARD_LEAD_SOURCE_DATA_BEGIN,
        };
    },

    qualityDashboardLeadSourceDataSuccess: (data,start,end) => {
        return {
            type: actions.QUALITY_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    qualityDashboardLeadSourceDataErr: (err) => {
        return {
            type: actions.QUALITY_DASHBOARD_LEAD_SOURCE_DATA_ERR,
            err,
        };
    },

    qualityDashboardRegionDataBegin: () => {
        return {
            type: actions.QUALITY_DASHBOARD_REGION_DATA_BEGIN,
        };
    },

    qualityDashboardRegionDataSuccess: (data,start,end) => {
        return {
            type: actions.QUALITY_DASHBOARD_REGION_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    qualityDashboardRegionDataErr: (err) => {
        return {
            type: actions.QUALITY_DASHBOARD_REGION_DATA_ERR,
            err,
        };
    },

    qualityDashboardCategoryDataBegin: () => {
        return {
            type: actions.QUALITY_DASHBOARD_CATEGORY_DATA_BEGIN,
        };
    },

    qualityDashboardCategoryDataSuccess: (data,start,end) => {
        return {
            type: actions.QUALITY_DASHBOARD_CATEGORY_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    qualityDashboardCategoryDataErr: (err) => {
        return {
            type: actions.QUALITY_DASHBOARD_CATEGORY_DATA_ERR,
            err,
        };
    },

    qualityDashboardTicketDataBegin: () => {
        return {
            type: actions.QUALITY_DASHBOARD_TICKET_DATA_BEGIN,
        };
    },

    qualityDashboardTicketDataSuccess: (data,start,end) => {
        return {
            type: actions.QUALITY_DASHBOARD_TICKET_DATA_SUCCESS,
            data,
            start,
            end,
        };
    },

    qualityDashboardTicketDataErr: (err) => {
        return {
            type: actions.QUALITY_DASHBOARD_TICKET_DATA_ERR,
            err,
        };
    },

    qualityDashboardSummaryDataBegin: () => {
        return {
            type: actions.QUALITY_DASHBOARD_SUMMARY_DATA_BEGIN,
        };
    },

    qualityDashboardSummaryDataSuccess: (data) => {
        return {
            type: actions.QUALITY_DASHBOARD_SUMMARY_DATA_SUCCESS,
            data,
        };
    },

    qualityDashboardSummaryDataErr: (err) => {
        return {
            type: actions.QUALITY_DASHBOARD_SUMMARY_DATA_ERR,
            err,
        };
    },
    
    qualityFormDataBegin: () => {
        return {
            type: actions.QUALITY_FORM_DATA_BEGIN,
        };
    },

    qualityFormDataSuccess: (data) => {
        return {
            type: actions.QUALITY_FORM_DATA_SUCCESS,
            data,
        };
    },

    qualityFormDataErr: (err) => {
        return {
            type: actions.QUALITY_FORM_DATA_ERR,
            err,
        };
    },

    qualityFormSubmitDataBegin: () => {
        return {
            type: actions.QUALITY_FORM_SUBMIT_DATA_BEGIN,
        };
    },

    qualityFormSubmitDataSuccess: (data) => {
        return {
            type: actions.QUALITY_FORM_SUBMIT_DATA_SUCCESS,
            data,
        };
    },

    qualityFormSubmitDataErr: (err) => {
        return {
            type: actions.QUALITY_FORM_SUBMIT_DATA_ERR,
            err,
        };
    },

    qualityFormSubmitDataReset: (err) => {
        return {
            type: actions.QUALITY_FORM_SUBMIT_DATA_RESET,
            err,
        };
    },


    getAuditQualityListDataBegin: () => {
        return {
            type: actions.GET_AUDIT_QUALITY_LIST_DATA_BEGIN,
        };
    },

    getAuditQualityListDataSuccess: (data) => {
        return {
            type: actions.GET_AUDIT_QUALITY_LIST_DATA_SUCCESS,
            data,
        };
    },

    getAuditQualityListDataErr: (err) => {
        return {
            type: actions.GET_AUDIT_QUALITY_LIST_DATA_ERR,
            err,
        };
    },

    submitAuditQualityShareDataBegin: () => {
        return {
            type: actions.SUBMIT_QUALITY_SHARE_DATA_BEGIN,
        };
    },

    submitAuditQualityShareDataSuccess: (data) => {
        return {
            type: actions.SUBMIT_QUALITY_SHARE_DATA_SUCCESS,
            data,
        };
    },

    submitAuditQualityShareDataErr: (err) => {
        return {
            type: actions.SUBMIT_QUALITY_SHARE_DATA_ERR,
            err,
        };
    },

    submitAuditQualityShareDataReset: (err) => {
        return {
            type: actions.SUBMIT_QUALITY_SHARE_DATA_RESET,
            err,
        };
    },

    getAgentQualityListDataBegin: () => {
        return {
            type: actions.GET_AGENT_QUALITY_LIST_DATA_BEGIN,
        };
    },

    getAgentQualityListDataSuccess: (data) => {
        return {
            type: actions.GET_AGENT_QUALITY_LIST_DATA_SUCCESS,
            data,
        };
    },

    getAgentQualityListDataErr: (err) => {
        return {
            type: actions.GET_AGENT_QUALITY_LIST_DATA_ERR,
            err,
        };
    },

    viewAuditQualityDataBegin: () => {
        return {
            type: actions.VIEW_AUDIT_QUALITY_DATA_BEGIN,
        };
    },

    viewAuditQualityDataSuccess: (data) => {
        return {
            type: actions.VIEW_AUDIT_QUALITY_DATA_SUCCESS,
            data,
        };
    },

    viewAuditQualityDataErr: (err) => {
        return {
            type: actions.VIEW_AUDIT_QUALITY_DATA_ERR,
            err,
        };
    },
    

    qualityReportDataBegin: () => {
        return {
            type: actions.QUALITY_REPORT_DATA_BEGIN,
        };
    },

    qualityReportDataSuccess: (data) => {
        return {
            type: actions.QUALITY_REPORT_DATA_SUCCESS,
            data,
        };
    },

    qualityReportDataErr: (err) => {
        return {
            type: actions.QUALITY_REPORT_DATA_ERR,
            err,
        };
    },

    qualityReportSaveDataBegin: () => {
        return {
            type: actions.QUALITY_REPORT_SAVE_DATA_BEGIN,
        };
    },

    qualityReportSaveDataSuccess: (data) => {
        return {
            type: actions.QUALITY_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    qualityReportSaveDataErr: (err) => {
        return {
            type: actions.QUALITY_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    qualityReportDownloadDataBegin: () => {
        return {
            type: actions.QUALITY_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    qualityReportDownloadDataSuccess: (data) => {
        return {
            type: actions.QUALITY_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    qualityReportDownloadDataErr: (err) => {
        return {
            type: actions.QUALITY_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },
};

export default actions;
