import React,{ useState, useEffect }  from 'react';
import { Row, Col, Form, Input,  Select, Drawer, Button, notification, Checkbox } from 'antd';
import { useSelector,useDispatch } from 'react-redux';
import Heading from '../heading/heading';
import { createCustomEmployee, vendorPincodeList } from '../../redux/employee/actionCreator';
import { adminPortalIcon, vendorPortalIcon } from '../../utility/portalIcon';
import { getStateList } from '../../redux/common/actionCreator';

const { Option } = Select;

const CreateEmployee = ({createEmployee,setCreateEmployee,setPassData,...props}) => {

    const [form] = Form.useForm();
    const employeeDetails = props.employeeData;

    useEffect(() => {
        if(createEmployee){
            form.resetFields();
        }
        
    },[form,createEmployee])

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getStateList());
    },[dispatch])

    const onClose = () => {
        setCreateEmployee(false);
    }

    const [loading, setLoading] = useState(false); 

    const handleSubmit = (value) => {
        try {
            
            const modifiedValue = { ...value,permission:checkedList};
            
            dispatch(createCustomEmployee(modifiedValue));
            form.resetFields();
            setLoading(true); 
            setCreateEmployee(false);
            notification.success({
                message: 'Submitted Successfully',
            });
        } catch (error) {
            notification.error({
                message: error,
            });
            
        } finally {
            // setLoading(false); // Reset loading state after form submission is complete
        }
    }

    
    const initialValues = {
        name: employeeDetails?.name,
        email: '',
        mobile: '',
        alternative:'',
        dob:'',
        anniversary:'',
        address_1:'',
        address_2:'',
        address_3:'',
        area:'',
        city:'',
        pincode:'',
    };

    const validatePassword = (_, value) => {
        if (value && value.length < 8) {
            return Promise.reject(new Error('Password must be at least 8 characters'));
        }
        return Promise.resolve();
    };

    const [list,setList]                                    = useState([]);
    const [showPermissionControl,setShowPermissionControl]  = useState(false);
    const [checkedList, setCheckedList]                     = useState([]);
    const [indeterminate, setIndeterminate]                 = useState(false);
    const [checkAll, setCheckAll]                           = useState(false);
    const [showVendorPincode,setShowVendorPincode]          = useState(false);
    const [showStateList, setShowStateList]                 = useState(false);

    const onHandleUserType = (value) => {
        setShowVendorPincode(false);
        setShowStateList(false);
        setShowPermissionControl(true);
        if(value === 'admin'){
            setList(adminPortalIcon);
            setShowStateList(true);
        }
       
        if(value === 'vendor'){
            setList(vendorPortalIcon);
            setShowVendorPincode(true);
            dispatch(vendorPincodeList());
        }
        
        form.setFieldsValue({
            user_type: value,
        });
        setCheckedList([]);
        setIndeterminate(false);
        setCheckAll(false);
    }

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ?  list.map(li => li.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const onChange = (checkedValues) => {
        setCheckedList(checkedValues);
        setIndeterminate(!!checkedValues.length && checkedValues.length < list.length);
        setCheckAll(checkedValues.length === list.length);
    };

    const pincodeList   = useSelector((state) => state.vendorPincodeList?.data);

    const stateListData = useSelector((state) => state.stateList.data);
    
  
    return (
        <>
            <Drawer title="Create Employee" width={640} onClose={onClose} open={createEmployee} bodyStyle={{ paddingBottom: 80, }}>
                <Form layout="vertical" form={form} initialValues={initialValues} onFinish={handleSubmit}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="username" label="Employee ID" rules={[{ required: true, message: 'Please enter Employee ID' }]} >
                                <Input.Group compact>
                                    <Input style={{ width: '15%' }} defaultValue="C" disabled />
                                    <Form.Item name="username" noStyle rules={[{ required: true, message: 'Please enter Employee ID' }]}>
                                        <Input style={{ width: '85%' }} placeholder="Please Enter Employee ID" />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="password" label="Password"   rules={[ { required: true, message: 'Please Enter Password' },{ validator: validatePassword }]} >
                                <Input.Password placeholder="Please Enter Password" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="first_name" label="First Name" rules={[ { required: true, message: 'Please Enter First Name', },]} >
                                <Input placeholder="Please Enter First  Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="last_name" label="Last Name" rules={[ { required: false, message: 'Please Enter Last Name', },]} >
                                <Input placeholder="Please Enter Last Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="mobile" label="Mobile" rules={[
                                { type: 'number', message: 'Please enter Mobile Number!',
                                    asyncValidator: (rules, value) => {
                                        return new Promise((resolve, reject) => {
                                            if (/^[0-9]+$/.test(value)) {
                                                resolve();
                                            } else {
                                                reject();
                                            }
                                        });
                                    },
                                }, 
                                { required: true, message: 'Please enter Mobile', }, ]}>
                                <Input placeholder="Please enter Mobile" maxLength={10}   />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="email" label="Email" rules={[ { required: false, message: 'Please Enter Employee Email', },]} >
                                <Input placeholder="Please Enter Employee Email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Heading as="h4"> Permission </Heading>
                    <Row gutter={24}>
                        <Col span={12} xs={24} lg={12} >
                            <Form.Item name="user_type" label="Login Type" rules={[ { required: true, message: 'Please Choose any one Login Type' }]} >
                                <Select placeholder="Please choose the Login Type" onChange={onHandleUserType} allowClear>
                                    <Option value="admin">Admin</Option>
                                    <Option value="vendor">Vendor</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        { showStateList && (
                        <Col span={12} xs={24} lg={12} >
                            <Form.Item name="allow_state" label="Allow State" rules={[ { required: true, message: 'Please Choose any one Allow State' }]} >
                                <Select style={{ width: '100%' }} 
                                    mode="multiple"
                                    placeholder="Please choose the Allow State" 
                                    options={stateListData && stateListData.map(data => ({ label: data.name, value: data.id }))}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => ((option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    showSearch
                                    showArrow={true}
                                    notFoundContent={null}
                                    allowClear
                                    >
                                </Select>
                            </Form.Item>
                        </Col>
                        )}

                        { showVendorPincode && (
                            <Col span={12} xs={24} lg={12} >
                                <Form.Item name="pincode_list" label="Pincode" rules={[ { required: false, message: 'Please Choose any one Pincode', }, ]} >  
                                    <Select 
                                        mode="multiple"
                                        placeholder="Please choose any One Pincode" 
                                        options={pincodeList && pincodeList.map(data => ({ label: data.code, value: data.code }))}
                                        optionFilterProp="children"
                                        showSearch
                                        showArrow={true}
                                        notFoundContent={null}
                                        allowClear
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                        
                    </Row>


                    { showPermissionControl && (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Heading as="h4"> 
                                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} >
                                            Check All Portal
                                        </Checkbox>
                                    </Heading>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Checkbox.Group style={{ width: '100%' }} value={checkedList} onChange={onChange}>
                                        <Row>
                                            {list && list.map((lis,key) => (
                                                <Col xxl={6} xl={6} lg={6} xs={12} key={'col-'+key}>
                                                    <Checkbox key={'check'+key} value={lis.value}>
                                                        <div className="text-center">
                                                            <div className="text-center">
                                                                <img src={lis.image_url} width='100' alt='' />
                                                            </div>
                                                            {lis.title}
                                                        </div>
                                                    </Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Col>
                            </Row>
                        </>
                    )}
                    
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="sDash_form-action mt-30">
                                <Button type="danger" size="large"  onClick={onClose}>Close</Button>
                                <Button htmlType="submit" type="primary" size="large" disabled={loading} style={{float:'right'}}>
                                    {loading ? 'Please Wait...' : 'Submit'}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Drawer> 
        </>
    )
}

export default CreateEmployee