import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch,Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard                 = lazy(() => import('../../container/stock_defective/Dashboard'));
const StockDefectiveList        = lazy(() => import('../../container/stock_defective/StockDefectiveList'));
const ViewStockDefective        = lazy(() => import('../../container/stock_defective/ViewStockDefective'));
const EditStockDefective        = lazy(() => import('../../container/stock_defective/EditStockDefective'));
const HappyStockDefective       = lazy(() => import('../../container/stock_defective/HappyStockDefective'));

const UploadDump                = lazy(() => import('../../container/stock_defective/UploadDump'))
const UploadDumpStatus          = lazy(() => import('../../container/stock_defective/UploadReport'))

const Report                    = lazy(() => import('../../container/stock_defective/Report'));
const DownloadReport            = lazy(() => import('../../container/stock_defective/DownloadReport'));


function NotFound() {
    return <Redirect to="/admin" />;
}


function StockRoutes() {
    const { path } = useRouteMatch();
    
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
            <Route exact path={`${path}/dashboard`} component={Dashboard} />
            
            <Route exact path={`${path}/list`} component={StockDefectiveList} />
            <Route exact path={`${path}/list/view`} component={ViewStockDefective} />
            <Route exact path={`${path}/list/edit`} component={EditStockDefective} />

            <Route exact path={`${path}/pending_list`} component={HappyStockDefective} />
            <Route exact path={`${path}/list/view`} component={ViewStockDefective} />
            
            <Route exact path={`${path}/upload_dump`} component={UploadDump} />
            <Route exact path={`${path}/upload_dump_status`} component={UploadDumpStatus} />
            
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(StockRoutes);
