import React, { lazy } from 'react';
import { Switch, Route,Redirect ,useRouteMatch} from 'react-router-dom';
import withAdminLayout from '../../../layout/withAdminLayout';

const Dashboard         = lazy(() => import('../../../container/vendor/demoInstall/Dashboard'));
const RegisteredList    = lazy(() => import('../../../container/vendor/demoInstall/RegisteredList'));
const ViewDetails       = lazy(() => import('../../../container/vendor/demoInstall/ViewDetails'));


function NotFound() {
    return <Redirect to="/admin" />;
}

function DemoInstallRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/complaint`} component={RegisteredList} />
            <Route exact path={`${path}/complaint/view`} component={ViewDetails} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(DemoInstallRoutes);