import React, { Suspense, useState, useEffect } from 'react';
import { Row, Col, Table,  Pagination, Spin, Switch, notification, Modal  } from 'antd'; 
import FeatherIcon from 'feather-icons-react';

import { PageHeader } from '../../components/page-headers/page-headers';
import { Button } from '../../components/buttons/buttons';
import { Main, TableWrapper,  TablePagination,TableHeaderSearch } from '../styled';
import { useSelector,useDispatch } from 'react-redux';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { employeeFetchData, resetCustomEmployee, resetEditCustomEmployeeDetails, updateEmployeeLoginStatus, updateEmployeeStatus } from '../../redux/employee/actionCreator';
import ChangePassword  from '../../components/employee/changePassword';
import ViewDetails from '../../components/employee/viewDetails';
import CreateEmployee from '../../components/employee/createEmployee';
import EditCustomEmployee from '../../components/employee/editCustomEmployee';
import { capitalize } from '../../utility/localStorageControl';
import { alertModal } from '../../components/modals/antd-modals';
import { getStateList } from '../../redux/common/actionCreator';

const EmployeeList = () => {
    
    const dispatch = useDispatch();
    
    const [data,setData] = useState({
        'page': 1,
        'page_items_count': 50,
        'type':'custom',
        'search':'',
        'status':1,
    });

    useEffect(() => {
        if (employeeFetchData) {
            dispatch(employeeFetchData(data));
            dispatch(getStateList());
        }
    }, [dispatch,data]);
    
    const employee = useSelector((state) => state.employee.data);
    const stateListData     = useSelector((state) => state.stateList.data);

    const [tableData,setTableData] = useState([]);

    const handleSearch = (searchText) => {
        
        setData({...data,search:searchText,page:1});
    }


    const [showDetails,setShowDetails] = useState(false);
    const [showPermission,setShowPermission] = useState(false);
	const [showPassword,setShowPassword] = useState(false);
    const [editCustomEmployee, setEditCustomEmployee] = useState(false);

    const [showDelete,setShowDelete] = useState(false);

    const [passData,setPassData] = useState({
		employeeData:''
	})
    
	const showDrawer = (data,parent) => {
		
        
        if(data === 'view'){
            setPassData({employeeData:parent})
            setShowDetails(true)
        }
            
        if(data === 'new'){
            setPassData({employeeData:''});
            setCreateEmployee(true);
        }
            
    	if(data === 'edit'){
            setPassData({employeeData:parent})
            setEditCustomEmployee(true);
        }
            
		if(data === 'password'){
            setPassData({employeeData:parent})
            setShowPassword(true)
        }
             
        if(data === 'delete'){
            setPassData({employeeData:parent})
            setShowDelete(true)
        }
            
        
        
	}

    const getStateNames = (allowedStateIds) => {
        return allowedStateIds
            ?.map((stateId) => stateListData && stateListData.find((state) => state.id === stateId)?.name)
            .filter((name) => name) // Exclude undefined names
            .join(", ");
    };
      
    const columns = [
        {
            title: 'Employee ID',
            dataIndex: 'employeeId',
            key: 'employeeId',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.employeeId <  b.employeeId ? -1 : 1,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Employee Name',
            dataIndex: 'employeeName',
            key: 'employeeName',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile'
        },
        {
            title: 'User Type',
            dataIndex: 'userType',
            key: 'userType',
            render:(_,{userType,department})=> (
                <>
                    <span className={`status-custom ${userType?.toLowerCase()}`}> 
                        {userType && capitalize(userType)}
                        {userType && department && ' - '}
                        {department && capitalize(department)}
                    </span>
                </>
            ),
        },
        {
            title:'Allow State',
            dataIndex: 'allowed_state',
            key: 'allowed_state',
            render: (allowed_state) => (
                <div>
                    {allowed_state && allowed_state.length > 0 && (getStateNames(allowed_state))}
                </div>
            ),
        },
        {
            title:'Is Login',
            dataIndex: 'is_login',
            key: 'is_login',
            render: (is_login, record) => (
                record.status === true ? 
                <Switch
                  checked={is_login}
                  onChange={(checked) => userAllowLogin(checked, record.key)}
                  checkedChildren="Active" unCheckedChildren="Block"
                /> : ''
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => (
                // <Switch
                //   checked={status}
                //   onChange={(checked) => userStatus(checked, record.key)}
                //   checkedChildren="Active" unCheckedChildren="Block"
                // />
                status === true ? <span className={`status-text active`}>{'Active'}</span> : <span className={`status-text blocked`}>{'Block'}</span>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 200,
        },
    ];

    useEffect(() => {
        const dataTable = [];
        if (employee && employee.object_list ){
            employee.object_list.map((value) => {
                const { id, email, employee_id, is_login,mobile,name,user_type,username,status,allowed_state,branch,employee_category,sap_status } = value;
                return dataTable.push({
                    key: id,
                    employeeId: employee_id,
                    employeeName: capitalize(name),
                    mobile: mobile,
                    email: email,
                    userType:capitalize(user_type),
                    allowed_state:allowed_state,
                    is_login : is_login,
                    status: status,
                    action: (
                        <div className="table-actions">
                            <>
                                <Button className="btn-icon" type="primary" shape="circle" title="View Employee" onClick={() => showDrawer('view',value)}>
                                    <FeatherIcon icon="eye" size={16} /> 
                                </Button>
                                <Button className="btn-icon" type="primary" shape="circle" title="Edit Employee" onClick={() => showDrawer('edit',value)}>
                                    <FeatherIcon icon="edit" size={16} /> 
                                </Button>
                                
                                <Button className="btn-icon" type="info" shape="circle" title="Change Password" onClick={() => showDrawer('password',value)}>
                                    <FeatherIcon icon="lock" size={16} /> 
                                </Button>
                                <Button className="btn-icon" type="info" shape="circle" title="Delete Employee"  onClick={() => showConfirm('delete',value)}>
                                    <FeatherIcon icon="trash" size={16} /> 
                                </Button>
                            </>
                        </div>
                    ),
                });
            });
        }
        if(dataTable.length > 0){
            setTableData(dataTable);
        }
    },[employee])
   
    
    const userAllowLogin = (checked,id) => {
        dispatch(updateEmployeeLoginStatus({employee_id:id,is_login_status:checked}));
    }

    const employeeLoginStatus =  useSelector((state) => state.employeeLoginStatus);
    useEffect(() => {
        if(employeeLoginStatus.data?.id){
            notification.success({
                message: 'Submitted Successfully.....',
            });
            setTableData((prevData) =>
                prevData.map((item) =>
                    item.key === employeeLoginStatus.data.id ? { ...item, is_login: employeeLoginStatus.data.checked } : item
                )
            );
        }
    },[employeeLoginStatus]);

    
    const userStatus = (checked,id) => {
        dispatch(updateEmployeeStatus({employee_id:id,status:checked}));
    }

    const employeeStatus =  useSelector((state) => state.employeeStatus);
    useEffect(() => {
        if(employeeStatus.data?.id){
            notification.success({
                message: 'Submitted Successfully.....',
            });
            setTableData((prevData) =>
                prevData.map((item) =>
                    item.key === employeeStatus.data.id ? { ...item, status: employeeStatus.data.checked } : item
                )
            );
        }
    },[employeeStatus]);

 
    const onChangePagination =(page,pageSize) => {
        setData({ ...data, page:page, page_items_count:pageSize,})
    }

    const onChangePageSize =(current, size) => {
        setData({ ...data,  page:1, page_items_count:size})
    }

    const [createEmployee,setCreateEmployee]= useState(false);

    const showConfirm = (value) => {
        alertModal.confirm({
          title: 'Do you want to delete these items?',
          content: 'When clicked the OK button, this dialog will be closed after 1 second',
            onOk() {
                return new Promise((resolve, reject) => {
                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => {});
            },
          onCancel() {},
        });
    };
    

    // Permission Update
    const employeeManualEditData = useSelector((state) => state.employeeManualEdit);
    useEffect(() => {
        if(employeeManualEditData?.data === 'submitted' && employeeManualEditData?.loading === false){
            Modal.info({
                title: 'Permission has been updated successfully.',
                okText: 'Ok',
                footer: null, // Disable the default footer
                onOk() {
                    
                    dispatch(resetEditCustomEmployeeDetails());
                    dispatch(employeeFetchData(data));
                },
            });
        }

        if(employeeManualEditData?.error  && employeeManualEditData?.loading === false){
            Modal.error({
                title: 'Please try Again',
                okText: 'Ok',
                footer: null, // Disable the default footer
                onOk() {
                    
                    dispatch(resetEditCustomEmployeeDetails());
                    dispatch(employeeFetchData(data));
                },
            });
        }
        //eslint-disable-next-line
    },[employeeManualEditData])


    // Create Custom Employee
    const employeeManualCreateData = useSelector((state) => state.employeeManualCreate);
    useEffect(() => {
        if(employeeManualCreateData?.data === 'submitted' && employeeManualCreateData?.loading === false){
            Modal.info({
                title: 'Employee has been created successfully.',
                okText: 'Ok',
                footer: null, // Disable the default footer
                onOk() {
                    
                    dispatch(resetCustomEmployee());
                    dispatch(employeeFetchData(data));
                },
            });
        }

        if(employeeManualCreateData?.error  && employeeManualCreateData?.loading === false){
            Modal.error({
                title: 'Please try Again',
                okText: 'Ok',
                footer: null, // Disable the default footer
                onOk() {
                    
                    dispatch(resetCustomEmployee());
                    dispatch(employeeFetchData(data));
                },
            });
        }
        //eslint-disable-next-line
    },[employeeManualEditData])



    return (
        <>
            <PageHeader
                ghost
                title="Custom Employee List"
                buttons={[
                    <TableHeaderSearch key={1}>
                        <div className="table-header-search-bar">
                            <div className="table-header-search-search">
                                <AutoComplete onSearch={handleSearch} placeholder="Search Employee" patterns />
                            </div>
                            <div key="2" className="page-header-actions">
                                <Button type="secondary" outlined size={'large'} style={{float: 'right'}} onClick={()=> showDrawer('new','')}>
                                    <img src={require('../../static/img/icon/New Customer.svg').default} alt="" /> Create Employee
                                </Button>
                            </div>
                        </div>
                    </TableHeaderSearch>
                ]}
            />
            <Main>
                <Suspense
                    fallback={
                        <Spin />
                    }
                >
                { employee?.object_list &&  employee?.object_list.length > 0 && (
                    <Row gutter={25}>
                        <Col sm={24} md={24}>
                            <TableWrapper className="table-responsive">
                                <Table  columns={columns} pagination={false} dataSource={tableData} scroll={{ y: 570 }} sticky />
                            </TableWrapper>
                        </Col>
                        <Col xs={24} className="mt-30"></Col>
                        <Col xs={24} >
                            <TablePagination>
                                <Pagination defaultCurrent={employee.current_page} total={employee.total_page*data.page_items_count} pageSize={data.page_items_count} pageSizeOptions={['50','100','200',]} onChange={onChangePagination} onShowSizeChange={onChangePageSize} />
                            </TablePagination>
                        </Col>
                    </Row>
                )}
                </Suspense>

                

                { createEmployee && (
                    <CreateEmployee createEmployee={createEmployee} setCreateEmployee={setCreateEmployee} {...passData} setPassData={setPassData} />
                )}

                { editCustomEmployee && (
                    <EditCustomEmployee editCustomEmployee={editCustomEmployee} setEditCustomEmployee={setEditCustomEmployee} {...passData} setPassData={setPassData} />
                )}
                
                { showDetails && (
                    <ViewDetails showDetails={showDetails} setShowDetails={setShowDetails} {...passData} setPassData={setPassData} />
                )}

                { showPassword && (
                    <ChangePassword showPassword={showPassword} setShowPassword={setShowPassword} {...passData} setPassData={setPassData} />
                )}

                

            </Main>
        </>
    )
}

export default EmployeeList