import actions from './actions';

const { 
    REVIEW_DASHBOARD_LEAD_SOURCE_DATA_BEGIN, REVIEW_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS, REVIEW_DASHBOARD_LEAD_SOURCE_DATA_ERR,
    REVIEW_DASHBOARD_CATEGORY_DATA_BEGIN, REVIEW_DASHBOARD_CATEGORY_DATA_SUCCESS, REVIEW_DASHBOARD_CATEGORY_DATA_ERR,
    REVIEW_DASHBOARD_TICKET_DATA_BEGIN, REVIEW_DASHBOARD_TICKET_DATA_SUCCESS, REVIEW_DASHBOARD_TICKET_DATA_ERR,
    REVIEW_DASHBOARD_SUMMARY_DATA_BEGIN, REVIEW_DASHBOARD_SUMMARY_DATA_SUCCESS, REVIEW_DASHBOARD_SUMMARY_DATA_ERR,
    REVIEW_DASHBOARD_REGION_DATA_BEGIN, REVIEW_DASHBOARD_REGION_DATA_SUCCESS, REVIEW_DASHBOARD_REGION_DATA_ERR,
    
    REVIEW_QUERIES_DATA_BEGIN, REVIEW_QUERIES_DATA_SUCCESS, REVIEW_QUERIES_DATA_ERR, 
    VIEW_REVIEW_DETAIL_DATA_BEGIN, VIEW_REVIEW_DETAIL_DATA_SUCCESS, VIEW_REVIEW_DETAIL_DATA_ERR,
    REVIEW_HISTORY_DATA_BEGIN, REVIEW_HISTORY_DATA_SUCCESS,REVIEW_HISTORY_DATA_ERR, 

    ASSIGN_REVIEW_DATA_BEGIN, ASSIGN_REVIEW_DATA_SUCCESS, ASSIGN_REVIEW_DATA_ERR, ASSIGN_REVIEW_DATA_RESET,

    UPDATE_REVIEW_DATA_BEGIN, UPDATE_REVIEW_DATA_SUCCESS, UPDATE_REVIEW_DATA_ERR, UPDATE_REVIEW_DATA_RESET,
    
    REVIEW_REPORT_DATA_BEGIN, REVIEW_REPORT_DATA_SUCCESS, REVIEW_REPORT_DATA_ERR,
    REVIEW_REPORT_SAVE_DATA_BEGIN, REVIEW_REPORT_SAVE_DATA_SUCCESS, REVIEW_REPORT_SAVE_DATA_ERR, 
    REVIEW_REPORT_DOWNLOAD_DATA_BEGIN, REVIEW_REPORT_DOWNLOAD_DATA_SUCCESS, REVIEW_REPORT_DOWNLOAD_DATA_ERR, 
    
} = actions;

const initialState = { data: [], loading: false, error: null, };

const initialStateDashboard = { data: '', start:null, end:null, loading: false, error: null, };

const reviewDashbaordLeadSourceReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case REVIEW_DASHBOARD_LEAD_SOURCE_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case REVIEW_DASHBOARD_LEAD_SOURCE_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case REVIEW_DASHBOARD_LEAD_SOURCE_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const reviewDashboardCategoryReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case REVIEW_DASHBOARD_CATEGORY_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case REVIEW_DASHBOARD_CATEGORY_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case REVIEW_DASHBOARD_CATEGORY_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const reviewDashboardTicketReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case REVIEW_DASHBOARD_TICKET_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case REVIEW_DASHBOARD_TICKET_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case REVIEW_DASHBOARD_TICKET_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const reviewDashbaordSummaryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case REVIEW_DASHBOARD_SUMMARY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case REVIEW_DASHBOARD_SUMMARY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case REVIEW_DASHBOARD_SUMMARY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const reviewDashbaordRegionReducers = (state = initialStateDashboard, action) => {
    const { type, data, start, end, err } = action;
    switch (type) {
        case REVIEW_DASHBOARD_REGION_DATA_BEGIN:
            return {
                ...initialStateDashboard,
                loading: true,
            };
        case REVIEW_DASHBOARD_REGION_DATA_SUCCESS:
            return {
                ...initialStateDashboard,
                data,
                start,
                end,
                loading: false,
            };
        case REVIEW_DASHBOARD_REGION_DATA_ERR:
            return {
                ...initialStateDashboard,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const reviewEnquiresReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case REVIEW_QUERIES_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case REVIEW_QUERIES_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case REVIEW_QUERIES_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const reviewViewDetailsReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case VIEW_REVIEW_DETAIL_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case VIEW_REVIEW_DETAIL_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case VIEW_REVIEW_DETAIL_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const reviewHistoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case REVIEW_HISTORY_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case REVIEW_HISTORY_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case REVIEW_HISTORY_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const assignAgentReviewReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ASSIGN_REVIEW_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ASSIGN_REVIEW_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ASSIGN_REVIEW_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case ASSIGN_REVIEW_DATA_RESET:
            return initialState

        default:
            return state;
    }
};

const updateAgentReviewReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_REVIEW_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case UPDATE_REVIEW_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case UPDATE_REVIEW_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case UPDATE_REVIEW_DATA_RESET:
            return initialState

        default:
            return state;
    }
};


const reviewReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case REVIEW_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case REVIEW_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case REVIEW_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const reviewReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case REVIEW_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case REVIEW_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case REVIEW_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const reviewReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case REVIEW_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case REVIEW_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case REVIEW_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};



export { 
    reviewDashbaordLeadSourceReducers, reviewDashboardCategoryReducers, reviewDashboardTicketReducers, reviewDashbaordSummaryReducers, reviewDashbaordRegionReducers, 
    reviewEnquiresReducers, reviewViewDetailsReducers, reviewHistoryReducers,  assignAgentReviewReducers, updateAgentReviewReducers,
    reviewReportReducers, reviewReportSaveReducers, reviewReportDownloadReducers  
};
