import React, { lazy } from 'react';
import { Switch, Route,Redirect ,useRouteMatch} from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard                 = lazy(() => import('../../container/outofwarranty/Dashboard'));
const RegisteredList            = lazy(() => import('../../container/outofwarranty/RegisteredList'));
const ViewDetails               = lazy(() => import('../../container/datamanagement/ViewDetails'));
const EditDetails               = lazy(() => import('../../container/datamanagement/EditComplaintDetails'))

const HappyList                 = lazy(() => import('../../container/outofwarranty/HappyList'));
const ScheduledList             = lazy(() => import('../../container/outofwarranty/ScheduledList'));
const PriorityList              = lazy(() => import('../../container/outofwarranty/PriorityList'));
const Report                    = lazy(() => import('../../container/outofwarranty/Report'));
const DownloadReport            = lazy(() => import('../../container/outofwarranty/DownloadReport'));


function NotFound() {
    return <Redirect to="/admin" />;
}

function OutOfWarrantyRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />

            <Route exact path={`${path}/complaint`} component={RegisteredList} />
            <Route exact path={`${path}/complaint/view`} component={ViewDetails} />
            <Route exact path={`${path}/complaint/edit`} component={EditDetails} />

            <Route exact path={`${path}/complaint_happy`} component={HappyList} />
            <Route exact path={`${path}/complaint_happy/view`} component={ViewDetails} />

            <Route exact path={`${path}/priority`} component={PriorityList} />
            <Route exact path={`${path}/priority/view`} component={ViewDetails} />

            <Route exact path={`${path}/scheduled`} component={ScheduledList} />
            <Route exact path={`${path}/priority/view`} component={ViewDetails} />


            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(OutOfWarrantyRoutes);